.chkingdiv {
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: left;
  padding: 40px 0px;
  padding-left: 25px;
}

.empty-message {
  margin: 0px !important;
  font-family: Montserrat-SemiBold;
  font-size: 30px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
  margin: 0px !important;
}

.Contentc {
  width: 100%;
  /* padding: 40px 0px 40px 32px; */
  background: #111214;
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

/* .Contentc {
  width: 100%;
  padding: 40px 112px 40px 32px;
  background: #111214;
  gap: 30px;
  display: flex;
  flex-direction: column;
} */

.ContentLibrary1 {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ContentLibrary1_div1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ContentLibrary1_div1_p {
  margin: 0px !important;
}

.ContentLibrary1_div1_p1 {
  font-family: Montserrat-SemiBold;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0f6fff;
  margin: 0px !important;
}

.ContentLibrary1_div1_p1span1 {
  font-family: Montserrat-SemiBold;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  margin: 0px !important;
}

.ContentLibrary1_div1_p1span2 {
  font-family: Montserrat-Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  margin: 0px !important;
}

.ContentLibrary1_div1_p1span3 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0px !important;
  color: #9ea0a5;
}

.ContentLibrary1_div2 {
  width: Hug (118px) px;
  height: Hug (40px) px;
  padding: 10px 20px 10px 20px;
  gap: 7px;
  border-radius: 8px;
  background: #393c43;
}

/* ............ */

.dropdownmain11 {
  background: transparent !important;
  border: none !important;
  padding: 0px !important;

}

.DropDownmenuOptionDivvvvv {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  gap: 12px !important;
  border: 1px solid transparent !important;
  /* 
width: Hug (182px)px;
height: Hug (40px)px; */
  padding: 8px !important;
  border-radius: 8px !important;
}

.DropDownmenuOptionDivvvvv:hover {
  opacity: 0.7 !important;

  border: 1px solid #0F6FFF !important;

  background: #393C43 !important;

  color: #FFFFFF !important;


}




.DropDownmenuOptiondiv {
  /* width: 170px; */
  /* height: 75px; */
  padding: 20px !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 15px !important;
  border-radius: 8px !important;
  background: #393C43 !important;

}


.DropDownmenuOptionA {
  font-family: Montserrat-Medium !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 17.07px !important;
  text-align: left !important;
  text-underline-position: from-font !important;
  text-decoration-skip-ink: none !important;
  color: #9EA0A5 !important;
  margin: 0px 0px 20px 0px !important;
}

.DropDownmenuOptionA:hover {
  color: #FFFFFF !important;
  background: transparent !important;
}






.DropDownmenuOption {
  font-family: Montserrat-Medium !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 17.07px !important;
  text-align: left !important;
  text-underline-position: from-font !important;
  text-decoration-skip-ink: none !important;
  color: #9EA0A5 !important;
  margin: 0px !important;
}

.DropDownmenuOption:hover {
  color: #FFFFFF !important;
  background: transparent !important;
}


/* ............. */
.dropdownmain {
  width: 118px !important;
  height: 40px !important;
  /* padding: 10px 20px 10px 20px !important; */
  border-radius: 8px !important;
  background: #393c43 !important;
  gap: 7px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  border: none !important;
}

.dropdownmain1 {
  display: flex !important;
  gap: 4px !important;
  align-items: center !important;
  justify-content: center !important;
}

.dropdownmain1ptag {
  font-family: Montserrat-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #9ea0a5;
  margin: 0px !important;
}

/* .................... */




.carddivMain {
  display: flex;
  flex-direction: column;
  width: 324px;
  height: 414px;
  padding: 12px 12px 20.88px 12px;
  gap: 0px;
  border-radius: 16px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border: 1px solid #0F6FFF;
  box-shadow: 0px 0px 0px 4px #0F6FFF40;

}





.ContentLibrary1 {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ContentLibrary1_div1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ContentLibrary1_div1_p {
  margin: 0px !important;
}

.ContentLibrary1_div1_p1 {
  font-family: Montserrat-SemiBold;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0f6fff;
  margin: 0px !important;
}

.ContentLibrary1_div1_p1span1 {
  font-family: Montserrat-SemiBold;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  margin: 0px !important;
}

.ContentLibrary1_div1_p1span2 {
  font-family: Montserrat-Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  margin: 0px !important;
}

.ContentLibrary1_div1_p1span3 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0px !important;
  color: #9ea0a5;
}

.ContentLibrary1_div2 {
  width: Hug (118px) px;
  height: Hug (40px) px;
  padding: 10px 20px 10px 20px;
  gap: 7px;
  border-radius: 8px;
  background: #393c43;
}

/* ............. */

/* .................... */


.videos {
  position: absolute;
  /* width: 200px;
  height: 200px; */

  width: 300px;
  height: 240px;
  border-radius: 10px;
  background: #0000004D;




}

.thumbnail-image {
  position: absolute;
  /* width: 100%;
  height: 100%; */
  object-fit: cover;
  /* z-index: 1; */
  pointer-events: none;

  width: 300px;
  height: 240px;
  border-radius: 10px;
  background: #0000004D;


}

.itemm {
  position: relative;
  border-radius: 8px;
  overflow: hidden;


  width: 324px;
  /* height: 414px; */
  padding: 12px 12px 20.88px 12px;
  border-radius: 16px;
  background: #24262B;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 1px solid transparent;
  cursor: pointer;
}

.itemm:focus-within {
  background: #393C43;
  border: 1px solid #0F6FFF;
  box-shadow: 0px 0px 0px 4px #0F6FFF40;
}








.itemm:hover {
  /* background: #393C43;
border: 1px solid #0F6FFF;
box-shadow: 0px 0px 0px 4px #0F6FFF40; */

  opacity: 0.7;
}


.deleteiconsss:hover {
  opacity: 0.7;
}


.playB {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background: #24262b;
  border: 1px solid #0f6fff;
  backdrop-filter: blur(14px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}



.containerr {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 30px;
  background-color: #121212;
}









.image {
  object-fit: cover;
  width: 300px;
  height: 240px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* ................ */




.videowrapper1 {
  background: #0000004D;
  width: 200px;
  height: 200px;
  border-radius: 16px;
  justify-content: center;
  width: 300px;
  height: 240px;
  border-radius: 10px;
  /* width: 100%; */
  /* height: auto; */
  display: flex;
  flex-direction: column;
  gap: 15px;

  align-items: center;


}



.play-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}


.playB {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background: #24262B;
  border: 1px solid #0F6FFF;
  backdrop-filter: blur(14px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}



.ContentLibraryCards_div2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ContentLibraryCards_div2_ptag {
  font-family: Montserrat-SemiBold;

  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  letter-spacing: -0.03em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
  margin: 0px !important;
}

.ContentLibraryCards_p {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #9EA0A5;
  margin: 0px !important;
}

.ContentLibraryCards_lastdiv {
  display: flex;
  gap: 3px;
  align-items: center;
}

.ContentLibraryCards_lastdivPtag {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400;
  line-height: 14.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #9EA0A5;
  margin: 0px !important;
}




@media (max-width: 750px) {
  .ContentLibrary1_div1 {
    flex-direction: column;
    gap: 5px;
  }


  .ContentLibrary1_div1_p1,
  .ContentLibrary1_div1_p1span1,
  .ContentLibrary1_div1_p1span2,
  .ContentLibrary1_div1_p1span3 {
    font-size: 20px;
    line-height: 10px;
  }

}



@media (max-width: 650px) {

  .containerr {
    align-items: center;
    justify-content: center;
  }
}



@media (max-width: 520px) {
  .thumbnail-image {
    width: 85%;
    height: 55%;
  }

  .videos {
    width: 87%;
  }

  .Contentc {
    /* padding: 40px; */
  }


  .ContentLibrary1_div1 {
    gap: 20px;
  }

  .image {
    width: 100%;
  }

  .thumbnail-image {
    width: 90%;
    height: auto;
  }

}


@media (max-width: 410px) {
  /* .Contentc {
    padding: 25px;
  } */

  .videowrapper1 {
    /* background: #0000004d; */
    width: 100%;
  }
}

@media (max-width: 500px) {
  .ContentLibrary1_div1 {
    flex-wrap: wrap;
  }

  .videowrapper1 {

    width: 100%;
  }

}

@media (max-width: 850px) {
  .Contentc {
    width: 100%;
    /* padding: 70px 45px 40px 45px; */

  }

}


@media (max-width: 330px) {
  .itemm {
    padding: 12px 11px 20.88px 11px;
  }

  .Contentc {
    width: 100%;
    /* padding: 70px 15px 40px 15px; */
  }

  .videos {
    width: 92%;
  }
}










/* ................ */
















.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  /* Dim background */
  backdrop-filter: blur(8px);
  /* Apply blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure it's above other elements */
}

.popup-content {
  background: transparent;
  padding: unset;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  width: 90%;
  z-index: 1001;
  /* Ensure it's above the overlay */
}

.popup-content img {
  max-width: 100%;
  height: auto;
  display: block;
}






@media (max-width: 700px) {

  .ContentLibrary1_div1_p1,
  .ContentLibrary1_div1_p1span1,
  .ContentLibrary1_div1_p1span2,
  .ContentLibrary1_div1_p1span3 {
    font-size: 18px;
  }


}

@media (max-width: 550px) {

  .ContentLibrary1_div1_p1,
  .ContentLibrary1_div1_p1span1,
  .ContentLibrary1_div1_p1span2,
  .ContentLibrary1_div1_p1span3 {
    font-size: 16px !important;
  }
}

@media (max-width: 520px) {

  .ContentLibrary1_div1_p1,
  .ContentLibrary1_div1_p1span1,
  .ContentLibrary1_div1_p1span2,
  .ContentLibrary1_div1_p1span3 {
    font-size: 14px !important;
    line-height: 10px !important;
  }



  .Contentc {
    /* padding: 40px; */
  }

  .dropdownmain {

    height: 35px !important;
  }
}

.dddrop {
  cursor: pointer;
}


/* .dddrop:hover{
  opacity: 0.7;
} */




@media (max-width: 500px) {
  .ContentLibrary1_div1 {
    gap: 20px;
  }

  .dddropppppp {
    width: 100%;
  }

  .dddrop {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}

@media (max-width: 410px) {
  .Contentc {
    /* padding: 25px; */
  }
}

@media (max-width: 500px) {
  .ContentLibrary1_div1 {
    flex-wrap: wrap;
  }

  .dropdownmain {
    width: 100%;
  }




}



/* ............ */
@media(max-width:870px) and (min-width:769px) {
  .ContentLibrary1_div1 {
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;

  }
}

@media(max-width:850px) {
  .ContentLibrary1_div1 {
    flex-direction: row;
    align-items: center;
    gap: 10px;

  }

  /* .chkingdiv{
flex-direction: column;
  } */
  .details-overlay {
    display: flex;
    justify-content: center;
  }

  .containerr {
    justify-content: center;
  }

}




@media(max-width:450px) {


  .ContentLibraryDetails_main {
    width: 100% !important;
  }

  .chkingdiv {
    padding-left: 0px;
  }

}

@media(max-width:350px) {
  .dddrop {
    width: 100% !important;
  }

  .ContentLibraryDetails_main_btn {
    padding: 15px !important;
  }




  .ContentLibrary1_div1_p1,
  .ContentLibrary1_div1_p1span1,
  .ContentLibrary1_div1_p1span2,
  .ContentLibrary1_div1_p1span3 {
    font-size: 12px !important;
    line-height: 8px !important;
  }


}


@media(max-width:450px) {

  .ContentLibraryDetails_main1_1_ptag {
    font-size: 14px !important;

  }

  .ContentLibraryDetails_main {

    padding: 22px !important;
  }




}

.bottomdrawer {
  display: none;
}

.details-overlay {
  display: flex;
}

@media (max-width:769px) {
  .bottomdrawer {
    display: flex;
  }

  .details-overlay {
    display: none;
  }

  .ContentLibraryDetails_main {
    margin-top: 0px !important;
    border-radius: none;
    width: 100%;
  }
}

/* ................ */