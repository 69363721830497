.WinningProcess-main-container{
    background: #0F6FFF33;
    width: 100%;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    padding: 120px 0px 116px 0px;
}

.TrustedAgency-main-container-button-and-text-heading2 h2{
    font-family: Exo-Extra-Bold;
    font-size: 60px;
    font-weight: 800;
    line-height: 64px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0px !important;
    text-transform: uppercase;
    color: #ffffff; /* Base color of the text is white */
    text-shadow: 3px 3px 2.5px #0033cc,
      /* Blue shadow for the bottom */ 4px 4px 0px #000033; /* Dark blue shadow for deeper shading */
}

.TrustedAgency-main-container-button-and-text-heading2{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.TrustedAgency-main-container-button-and-text2{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-right: 100px;
    padding-left: 100px;
}

.trusted-btn2{
    width: 133px;
    height: 54px;
    border-radius: 16px;
    background: #0F6FFF;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Exo;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    margin: 0px;
    }
  .arrow-left-right{
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .slider-container {
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 98px;
  
  }
  
  .slider-content {
    display: flex;
    width: 100%;
  }
  
  .slide-group {
    display: flex;
    flex: 0 0 100%; /* Occupy full width for each group of slides */
    justify-content: space-between;
    padding-left: 100px;
  }
  
  .slide-card {
    width: 356px;
    height: 151px;
    padding: 30px 20px 30px 20px;
    border-radius: 20px 20px 20px 0px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    border: 1px solid #FFFFFF33   ;
    backdrop-filter: blur(150px);
    display: flex;
    flex-direction: column;
    gap: 12px;
    
  }
  
  .slide-title {
    font-family: Exo-Bold;
    font-size: 28px;
    font-weight: 700;
    line-height: 33.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;  
    text-transform: uppercase;
    background: linear-gradient(169.97deg, #FFFFFF 8.65%, #CDCDCD 93.64%);
   margin: 0px;
    -webkit-background-clip: text; /* Clip gradient to text (for WebKit browsers) */
    -webkit-text-fill-color: transparent; /* Makes text transparent to reveal gradient */
    background-clip: text; /* Standard property for modern browsers */
    color: transparent; /* Fallback in case gradient is unsupported */
  }
  
  .slide-description {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    margin: 0px;
  }
  .winning-line-image{
    width: 100%;
     padding-left: 52px;
     margin-top: 6px;
     position: relative;
  }
.line-dot-try-best-method{
    display: flex;
    gap: 250px;
    padding-left: 48px;
    position: absolute;
    top: -4px;
    left: 40px;
}

.bottom-cards-winning-main {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically if the height is defined */
    gap: 144px; /* Add spacing between the cards */
    margin-top: 20px; /* Adjust top margin if needed */
  }
  
  .bottom-card {
    width: 356px;
    height: 151px;
    padding: 30px 20px;
    border-radius: 20px 20px 20px 0px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)),
                linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    border: 1px solid #FFFFFF33;
    backdrop-filter: blur(150px);
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: left;
  }
  
  .bottom-card h3 {
    font-family: Exo-Bold;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    background: linear-gradient(169.97deg, #FFFFFF 8.65%, #CDCDCD 93.64%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
  }
  
  .bottom-card p {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    color: #FFFFFF;
    margin: 0;
  }
  /* General styles for large screens (default) */

  .new-try-for-border----------botom{
    border: 1px solid #0F6FFF;
    width: 100%;
    padding-left: 52px;
    margin-top: 6px;
    position: relative;

  }
@media (max-width:1200px) {
  
  .slide-group{
    gap: 40px;
  }
  .line-dot-try-best-method {
    gap: 129px;
  }
  .TrustedAgency-main-container-button-and-text2{
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
  }
}
@media (max-width:768px) {
  .line-dot-try-best-method {
    gap: 70px;
  }
  .bottom-cards-winning-main{
    flex-direction: column;
    gap: 50px;
  }
  .TrustedAgency-main-container-button-and-text-heading2 h2{
    font-size: 40px;
    line-height: 50px;
  }
  .slide-group {
    padding-left: 72px;
  }
  
}
@media (max-width:500px) {
  .bottom-card{
    width: 280px;
    height: unset;
  }
  .line-dot-try-best-method {
    gap: 30px;
  }
  .bottom-card p {
    font-size: 15px;

  }
  .arrow-left-right img{
    width: 50px !important;
  }
  .bottom-card h3{
    font-size: 20px;
  }
  
  .slide-title{
    font-size: 20px;
  }
  .slide-description {
    font-size: 15px;
  }
  .slide-group {
    padding: 25px;
  }
  .TrustedAgency-main-container-button-and-text2{
  
    padding-left: 25px !important;
    padding-right: 25px !important;
    
  }
  .TrustedAgency-main-container-button-and-text-heading2 h2{
   font-size: 37px;
  } 
  .WinningProcess-main-container {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .slider-container{
    margin-top: 30px;
  }
  .winning-line-image{
    left: -28px;
  }
  .cards_div{
    flex-direction: column !important;
    align-items: center !important;
  }
  .Payment-Methods-main-container p{
    font-size: 14px;
    line-height: 22px;
  }

}
