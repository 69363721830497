.Footer {
  background-color: #00061b;
  width: 100%;
  padding-top: 70px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.Footer1 {
  width: 100%;

  /* border-bottom: 1.05px solid rgba(15, 111, 255, 1); */
}
.Footer1-content {
  width: 100%;
  border-bottom: 1.05px solid rgba(15, 111, 255, 1);
  display: flex;
  padding-bottom: 83.5px;
  justify-content: space-between;
}
.LogoFooter {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.logo {
  height: 50px;
  width: 138.5px;
}
.FooterParaLogo {
  font-family: Montserrat;
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0;
  cursor: pointer;
}
.FooTERRight {
  display: flex;
  gap: 80px;
}
.footerRightCol {
  display: flex;
  flex-direction: column;
  gap: 16.8px;
}

.RightColHead {
  font-family: "Exo 2";
  font-weight: 500;
  line-height: 24px;
  font-size: 20px;
  margin-bottom: 0;
  background: linear-gradient(169.97deg, #ffffff 8.65%, #cdcdcd 93.64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* text-align: center; */
  cursor: pointer;
}
.rightColParas {
  display: flex;
  flex-direction: column;
  gap: 18.9px;
}
.ParaFooter1 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.94px;
  color: #cccdd1;
  margin-bottom: 0;
  text-decoration: none;
  cursor: pointer;
  transition: text-decoration 0.2s ease; /* Smooth transition for hover effect */
}

.ParaFooter1:hover {
  text-decoration: underline; /* Add underline on hover */
}
.RightColLast {
  display: flex;
  flex-direction: column;
  gap: 18.9px;
  justify-content: flex-end;
}
.sakinetfooter {
  font-family: Montserrat;
  font-size: 18px;
  line-height: 21.94px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0;
  cursor: pointer;
}
.FooterNumber {
  font-family: Montserrat;
  font-size: 18px;
  line-height: 21.94px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0;
  cursor: pointer;
}
.Footer2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 25px;
  align-items: center;
}
.copyRight {
  font-family: Montserrat;
  font-size: 18px;
  line-height: 21.94px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0;
  cursor: pointer;
}
.divSocials {
  display: flex;
  gap: 10px;
}
.Insta {
  font-family: Montserrat;
  font-size: 18px;
  line-height: 21.94px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0;
}
.social-links {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 10px; /* Space between links and separators */
  font-family: Montserrat;
  font-size: 18px;
  line-height: 21.94px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0;
  gap: 10px;
}
.social__links__icons {
  display: none;
}
.social-link {
  text-decoration: none; /* Remove underline */
  color: #ffffff; /* White text for links */
  transition: text-decoration 0.3s ease;
}

.social-link:hover {
  text-decoration: underline; /* Change color on hover */
}

.separator {
  color: #ffffff; /* White dots */
  font-size: 20px;
}
.copyRight {
  display: none;
}
.copy__right__text {
  display: none;
}
@media (max-width: 1201px) {
  .Footer1-content {
    flex-direction: column;
    gap: 40px;
  }
  .FooTERRight {
    justify-content: space-between;
  }
}
@media (max-width: 768px) {
  .Footer {
    padding-top: 40px;
  }
}
@media (max-width: 726px) {
  .Footer {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 665px) {
  .FooTERRight {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
  .Footer1 {
    padding-right: 0;
  }
  .footerRightCol {
    text-align: center;
  }
  .RightColLast {
    text-align: center;
  }
  .LogoFooter {
    align-items: center;
  }
  .FooterParaLogo {
    text-align: center;
  }
}

@media (max-width: 522px) {
  .Footer {
    padding-left: 24px;
    padding-right: 24px;
  }
  .copyRight {
    font-size: 16px;
  }
  .social-links {
    font-size: 16px;
    flex-wrap: wrap;
  }
}
@media (max-width: 500px) {
  .social__links__icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .social-links {
    display: none;
  }
  .Footer2 {
    justify-content: center;
    flex-direction: column;
  }
  .copy__right__text {
    display: flex !important;
    font-family: Montserrat !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 19.5px !important;
    text-align: center !important;
    color: #ffffff !important;
  }
}

@media (max-width: 332px) {
  .copyRight {
    font-size: 14px;
  }
  .social-links {
    font-size: 14px;
  }
  .copy__right__text 
  {
    font-size: 14px !important;
  }
}
