/* Main container for cards */
.Growing-cards-container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    padding-top: 70px;
   overflow: hidden;
    scroll-behavior: unset !important; /* Smooth scrolling for better UX */
    padding-left: 100px; /* Add left padding */
    transition: transform 2s ease-in-out;
    box-sizing: border-box; /* Ensure padding is included in width calculations */
    position: relative; /* Needed for the blurred background */
}

/* Background blur effect in the center */
.Growing-cards-container::before {
    content: "";
    position: absolute;
    top: 60%;
    left: 45%;
    transform: translate(-50%, -50%);
    width: 500px; /* Adjust size as needed */
    height: 200px; /* Adjust size as needed */
    background: #022EEB;
    filter: blur(150px); /* Controls the blur intensity */
    border-radius: 50%; /* Makes it circular */
    z-index: -2; /* Push behind the content */
    pointer-events: none; /* Makes it non-interactive */
}

/* Individual card styling */
.Growing-card {
    width: 400px;
    flex-shrink: 0; /* Prevent shrinking */
    border-radius: 30px;
    border-style: solid;
    background: #FFFFFF0D;
    backdrop-filter: blur(54px);
    padding: 60px 40px;
    border-color: #FFFFFF33;
    border: 1px solid #FFFFFF33;
    position: relative; /* For stacking purposes */
}

/* Arrow navigation */
.arrow-left-right img {
    cursor: pointer;
    transition:transform 0.3s; 
}

.arrow-left-right img:hover {
    transform: scale(1.1);
}

/* Card title styling */
.Growing-card h3 {
    font-family: Exo;
    font-size: 28px;
    font-weight: 800;
    line-height: 33.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    margin: 0px;
}

/* Card description styling */
.Growing-card p {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    margin: 20px 0px 0px 0px;
}

/* Growing image and text alignment */
.lorem-growing-part {
    display: flex;
    align-items: center;
    gap: 5px;
}

.lorem-growing-part p {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0px;
}

.growing-image-and-text {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 10px;
}


/* Adjusting container and card behavior */
.Growing-cards-container {
    display: flex;
    width: max-content; /* Dynamically adjusts to fit all cards */
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    padding-top: 70px;
    overflow: hidden;
    scroll-behavior: unset !important;
    padding-left: 100px;
    transition: transform 2s ease-in-out;
    box-sizing: border-box;
    position: relative;
  ;
 

}

/* Last card fix */
.Growing-card:last-child {
    margin-right: 100px; /* Add space for the last card */
}

/* Background blur effect in the center */
.Growing-cards-container::before {
    content: "";
    position: absolute;
    top: 60%;
    left: 45%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 200px;
    background: #022EEB;
    filter: blur(150px);
    border-radius: 50%;
    z-index: -2;
    pointer-events: none;
}

/* Card styling */
.Growing-card {
    width: 400px;
    flex-shrink: 0;
    border-radius: 30px;
    border: 1px solid #FFFFFF33;
    background: #FFFFFF0D;
    backdrop-filter: blur(54px);
    padding: 60px 40px;
    position: relative;
}

/* Arrow navigation */
.arrow-left-right img {
    cursor: pointer;
    transition: transform 0.3s;
}

.arrow-left-right img:hover {
    transform: scale(1.1);
}

/* Responsive design for smaller screens */
@media (max-width: 770px) {
    .Growing-cards-container {
        padding-left: 25px;
    }
}
/* Main container for cards */
.Growing-cards-container {
    display: flex;
    width: max-content; /* Adjust dynamically to fit all cards */
    gap: 20px;
    transition: transform 2s ease-in-out; /* Smooth sliding */
    padding-top: 70px;
    position: relative;
}

/* Ensure no horizontal scroll */
.Growing-cards-slider {
    overflow: hidden; /* Prevent horizontal scroll */
    position: relative;
    width: 100%; /* Ensure it spans the entire width of the parent */
   
}

/* Card styling */
.Growing-card {
    width: 400px;
    flex-shrink: 0; /* Prevent shrinking */
    border-radius: 30px;
    border: 1px solid #FFFFFF33;
    background: #FFFFFF0D;
    backdrop-filter: blur(54px);
    padding: 60px 40px;
    position: relative;
}

/* Arrow navigation */
.arrow-left-right img {
    cursor: pointer;
    transition: transform 0.3s;
}

.arrow-left-right img:hover {
    transform: scale(1.1);
}

/* Responsive design for smaller screens */
@media (max-width: 770px) {
    .Growing-cards-slider {
        padding-left: 25px;
    }

    .Growing-card {
        width: 300px; /* Adjust card size for smaller screens */
    }
}


@media (max-width:500px) {
    .Growing-cards-container{
        padding-left: 2px;
       padding-top: 30px;
    }
    
}