body {
  background: #111214 !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 400 */
@font-face {
  font-family: "Poppins";
  src: url("/public/FontFamily/Poppins-Regular.ttf") format("truetype");
}



@font-face {
  font-family: "Montserrat";
  src: url("/public/FontFamily/Montserrat-Regular.ttf") format("truetype");
}

/* 500 */
@font-face {
  font-family: "Montserrat-Medium";
  src: url("/public/FontFamily/Montserrat-Medium.ttf") format("truetype");
}

/* 600 */
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("/public/FontFamily/Montserrat-SemiBold.ttf") format("truetype");
}

/* 700 */
@font-face {
  font-family: "Montserrat-Bold";
  src: url("/public/FontFamily/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Exo2-Bold";
  src: url("/public/FontFamily/Exo2-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Exo2-Medium";
  src: url("/public/FontFamily/Exo2-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Exo2-SemiBold";
  src: url("/public/FontFamily/Exo2-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Exo2-Regular";
  src: url("/public/FontFamily/Exo2-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Exo2-ExtraBold";
  src: url("/public/FontFamily/Exo2-ExtraBold.ttf") format("truetype");
}

/* 500 */
@font-face {
  font-family: Exo;
  src: url("/public/FontFamily/Exo2-Regular.ttf") format("truetype");
}
@font-face {
  font-family: Exo-Extra-Bold;
  src: url("/public/FontFamily/Exo2-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: Exo-Bold;
  src: url("/public/FontFamily/Exo2-Bold.ttf") format("truetype");
}

body {
    overflow: auto !important;
}
html {
  margin: 0;
  padding: 0;
  overflow: scroll; 
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch; 
  scrollbar-width: none; 
}

html::-webkit-scrollbar {
  display: none; 
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.loader div {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  animation: wave 1.2s infinite ease-in-out;
}
.loader div:nth-child(2) {
  animation-delay: -1.1s;
}
.loader div:nth-child(3) {
  animation-delay: -1s;
}