.ContentLibraryDetails_main {
    width: 404px;
    height: fit-content;
    margin-top: 61px !important;
    padding: 30px 24px 30px 24px;
    border-radius: 16px;
    background: #24262B;
    backdrop-filter: blur(94px);
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.ContentLibraryDetails_main1 {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;

}

.ContentLibraryDetails_main1_1 {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 10px;
}

.ContentLibraryDetails_main1_1_ptag {
    font-family: Montserrat-SemiBold;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.94px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    margin: 0px !important;
}

.ContentLibraryDetails_main_btn {
    /* width: 356px;
height: 56px; */
    min-height: 56px;
    padding: 16px 28px 16px 28px;
    gap: 8px;
    border-radius: 16px;
    background: #0F6FFF;
    border: none;
    /* animation-duration: 0ms; */

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;

    font-family: Montserrat-SemiBold;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.006em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    margin: 0px !important;



}


.ContentLibraryDetails_mainDiv2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.ContentLibraryDetails_mainDiv2ptag {
    font-family: Montserrat-SemiBold;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.94px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    margin: 0px !important;
}

.ContentLibraryDetails_mainDiv2div {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.ContentLibraryDetails_mainDiv2div_ptag {
    font-family: Montserrat-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    margin: 0px !important;

}

.ContentLibraryDetails_mainDiv2div_ptag1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #9EA0A5;
    margin: 0px !important;
}

.ContentLibraryDetails_main___p {
    font-family: Montserrat-Bold;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.26px;
    letter-spacing: -0.01em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    margin: 0px !important;

}

.ContentLibraryDetails_mainDiv3 {
    background: #393c43;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 16px;
}

.ContentLibraryDetails_main_btn:hover {
    opacity: 0.7;
}

.ContentLibraryDetails_mainDiv3_P {
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 100%;
    font-family: Montserrat-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.006em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #D7D8D9;
    margin: 0px !important;
}

.ContentLibraryDetails_imgDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width:769px) {
    .ContentLibraryDetails_main {
        margin-top: 0px !important;
        border-radius: 0px !important;
        width: 100%;
    }
}