.SignUpMain1_2_first_Divnew {
  width: 420px;
  /* width: 100%; */
  height: 56px;
  padding: 16px;
  border-radius: 16px;
  background: #24262B;
  display: flex;
  gap: 8px;
  align-items: center;
}

.profileBottomTop {
  display: flex;
  flex-direction: column;
  /* gap: 24px; */
  margin-top: 50px;
}

.bottominnerprofile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 17px 24px;
  align-items: center;
  border-radius: 16px;
  background: #24262B;
  margin-bottom: 35px !important;
}

.bottominnerprofileptag {
  font-family: Montserrat-SemiBold;
  font-size: 16px;
  font-weight: 600;
  line-height: 24.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0F6FFF;
  margin: 0px !important;
}

.input-div-profile {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.two-input-maindiv-profile {
  display: flex;
  flex-direction: row;
  gap: 60px;
}

.main-profile-content {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.update-button-profile {
  border-radius: 16px;
  background-color: #0F6FFF;
  padding: 17px 80px;
  border: none;
  width: 61px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.update-text {
  font-family: Montserrat-SemiBold;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.006em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
}

.update-button-profile:hover {
  opacity: 0.8;
}

.profile-heading {
  font-family: Montserrat-SemiBold;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
}

.SignUpMain1_2_first_Divnew2 {
  width: 420px;
  /* width: 100%; */
  height: 56px;
  padding: 16px;
  border-radius: 16px;
  background: #393C43;
  display: flex;
  gap: 8px;
  align-items: center;
  border: 1px solid #0F6FFF;
  box-shadow: 0px 0px 0px 4px #164BF940;

}

.manage-heading {
  font-family: Montserrat-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #9EA0A5;
}

.profile-div {
  margin-bottom: 24px;
}

.topprofilenew {
  padding: 24px;
  border: 1px solid #393C43;
  border-radius: 16px;

}

.profile-main-div {
  padding: 25px 0px;
}

.Profile-Image-div {
  border-radius: 56px;
  border: 2px solid #0F6FFF;
  background-color: #393C43;
  height: 112px;
  width: 112px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/public/Images/ContentLibraryImg/ProfileBg.png   ");
  background-size: 100% 100%;
}

.profileImage-Upload {
  display: flex;
  flex-direction: row;
  align-items: end;
  margin-bottom: 35px;
}

.profile-image2 {
  height: 110px;
  width: 110px;
  border-radius: 56px;
  object-fit: contain;
}

.camera-div {
  margin-left: -35px;
}

.SignUpMain1_2_first_Divnew:focus-within {
  border: 1px solid #0F6FFF;
  box-shadow: 0px 0px 0px 4px #164BF940;

}

@media (max-width:1250px) {
  .two-input-maindiv-profile {
      flex-direction: column;
      gap: 35px;
  }
}

@media (max-width:500px) {

  .SignUpMain1_2_first_Divnew,
  .SignUpMain1_2_first_Divnew2 {
      width: 100%;
  }

  .profile-main-div {
      padding: 18px 10px;

  }

  .two-input-maindiv-profile {
      gap: 20px;
  }

  .main-profile-content {
      gap: 20px;
  }

  .profileImage-Upload {
      margin-bottom: 20px
  }

  .profile-div {
      margin-bottom: 30px;
  }

  .update-button-profile {
      padding: 14px 70px;
      font-size: 14px;
  }

  .profile-heading {
      font-size: 18px;
  }

  .manage-heading {
      font-size: 12px;
  }

  .Profile-Image-div {
      height: 90px;
      width: 90px;
  }

  .profile-image2 {
      height: 88px;
      width: 88px;
  }

  .camera-div {
      width: 29px;
      height: 24px;
      margin-left: -28px !important;
  }
}