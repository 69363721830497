.SignUpMainNext {
  width: 100%;
  background: #111214;
  display: flex;
  height: 100vh;
  overflow-y: hidden;
}
.SignUpMain11Next {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}
.alreadysignupnew {
  margin: 0px !important;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.3px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #9EA0A5;
}
.alreadysignup {
  margin: 0px !important;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.3px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #9EA0A5;
}
.alreadysignup2{
  cursor: pointer;
  margin: 0px !important;
  font-family: Montserrat-Bold;
font-size: 14px;
font-weight: 400;
line-height: 20.3px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
  color: #FFFFFF;
}
.alreadysignup2:hover{
  text-decoration: underline;
}
.SignUpMain1Next {
  width: 433px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: none;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 0px;
  padding-left: 0px;
}
.profile-divnew{
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  gap: 2px;
}
.SignUpMain1_1Next {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 65px;
  align-items: center;
}
.SignUpMain1_1ptagNext {
  font-family: Montserrat-Bold;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #FFFFFF;
  margin: 0px !important;
  white-space: nowrap;
}
.togglePasswordBtnNext {
  border: none;
  outline: none;
  background: none;
}
.SignUpMain1_2Next {
  display: flex;
  flex-direction: column;
  gap: 24px;
  /* margin-bottom: 60px; */
}
.SignUpMain1_2_firstNext {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.SignUpMain1_2_first_PNext {
  font-family: Montserrat-Bold;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #FFFFFF;
  margin: 0px !important;
}
.SignUpMain1_2_first_DivNext {
  width: 100%;
  height: 56px;
  padding: 16px;
  border-radius: 16px;
  background: #24262B;
  display: flex;
  gap: 8px;
  align-items: center;
}
.SignUpMain1_2_first_DivNext:focus-within {
  border: 1px solid #0F6FFF;
  box-shadow: 0px 0px 0px 4px #164bf940;
  background: #393C43;
}
.SignUpMain1_2_first_Div_TNext {
  font-family: Montserrat-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.006em;
  color: #D7D8D9;
  margin: 0px !important;
  background: transparent;
  border: none;
  width: 100%;
}
.SignUpMain1_2_first_Div_TNext:focus {
  outline: none;
}
.SignUpMain1_2_first_Div_TNext::placeholder {
  font-family: Montserrat-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.006em;
  color: #D7D8D9;
  margin: 0px !important;
}
.SignUpMain1_2BtnNext {
  width: 100%;
  height: 56px;
  padding: 16px 28px 16px 28px;
  border-radius: 16px;
  background: #0F6FFF;
  font-family: Montserrat-SemiBold;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  border: none;
  margin-top: 60px !important;
  margin-bottom: 17px !important;
}
.SignUpMain1_2BtnNext:hover {
  background: #FFFFFF;
  color:#000000;
}
.Forgot-Password {
  width: fit-content;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #9EA0A5;
  margin: 8px 0px 0px 0px;
}
.Forgot-Password:hover{
  color: #FFFFFF;
  text-decoration: underline;
}
@media (max-width: 1260px) {
  .SignUpImgNext {
    width: 55%;
  }
}

@media (max-width: 1060px) {
  .SignUpImgNext {
    display: none !important;
  }
  .SignUpMain11Next {
    width: 100% !important;
  }
  .SignUpMain1Next {
    width: 100% !important;
    padding: 0px 50px;
  }
}
@media (max-width: 600px) {
  .SignUpMain1Next {
    width: 100% !important;
    padding: 0px 25px;
  }
}