
.navbar__header___main__div {
  height: 80px !important;
  padding-left: 100px !important;
  padding-right: 100px !important;
  /* padding-top: 15px !important;
    padding-bottom: 15px !important; */
  max-width: unset !important;
  
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}
.navbar__main__div {
  height: 80px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 20px !important;
  align-items: center !important;
}
.buttons__navbar_container {
 
  gap: 33px;
  align-items: center;
}
.register__navbar__button {
  height: 47px !important;
  display: flex ;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 16px !important;
  background: rgba(15, 111, 255, 1) !important;
  border: none !important;
  width: 84px !important;
  font-family: "Exo 2" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 19.2px !important;
  color: rgba(255, 255, 255, 1) !important;
  transition: background 0.3s ease;
}
  .register__navbar__button:hover {
    background: rgba(10, 80, 200, 1) !important; /* Darker shade of blue */
  }
  .register__navbar {
    height: 48px !important;
    display: flex ;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 16px !important;
    background: rgba(15, 111, 255, 1) !important;
    border: none !important;
    width: 100% !important;
    font-family: "Exo 2" !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 19.2px !important;
    color: rgba(255, 255, 255, 1) !important;
    transition: background 0.3s ease;
  }
    .register__navbar:hover {
      background: rgba(10, 80, 200, 1) !important; /* Darker shade of blue */
    }
.navbar__items__container {
  display: flex !important;
  align-items: center !important;
  max-width: 490px !important;
  justify-content: space-between !important;
  width: 490px !important;
}
.items__navbar__text {
  font-family: Montserrat !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 21.94px !important;
  margin: 0 !important;

  text-transform: capitalize !important;
  padding: 0px !important;
}
.items__navbar__text:hover {
  font-family: Montserrat !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 21.94px !important;
  color: rgba(255, 255, 255, 1) !important;
}
.logo__image__header {
  padding-top: 0px !important;
}
/* .css-1toxriw-MuiList-root-MuiMenu-list{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
} */
@media (max-width: 1050px) {
  .navbar__header___main__div {
    padding-left: 6% !important;
    padding-right: 6% !important;
  }
}
@media (max-width: 900px) {
  .navbar__items__container {
    display: none !important;
  }
  .register__navbar__button {
    display: none !important;
  }
}
.css-1toxriw-MuiList-root-MuiMenu-list{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
/* @media (max-width: 400px) {
  .logo__image__header {
    height: 60px;
    width: 60px;
  }
} */
