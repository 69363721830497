

.SignUpMain1_111{
    display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 80px;
  align-items: center;
}


.email-divvv{
    width: 100px !important;
height: 100px !important;
/* padding: 12px 24px 12px 24px; */
border-radius: 16px !important;
background: #24262B !important;

font-family: Montserrat !important;
font-size: 34px !important;
font-weight: 400 !important;
line-height: 49.3px !important;
text-align: center !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
color: #D7D8D9 !important;
border: none !important;

}

.email-divvv:hover{
    opacity: 0.7;
    background: #393C43 !important;
    border: 1px solid #0F6FFF !important;
    box-shadow: 0px 0px 0px 4px #164BF940 !important;

}


.otpdiv {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    align-items: center !important;
    margin-bottom: 60px !important;
}

.otpdiv22{
    margin: 0px !important;
gap: 16px;


}
.SignUpMain111{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    }

.SignUpMain1111{
    /* max-width: 420px; */
    max-width: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 141px 150px 141px 150px; */
  /* overflow-y: auto; */
  /* overflow-y: hidden; */
  scrollbar-width: none;
  padding-top:50px ;
  padding-bottom:50px ;
  align-items: center;
}

.otp-input {
    border: 1px solid #BCBFCC80 !important;
    border-radius: 12px !important;
    /* padding: 10px 20px !important; */
    width: 100% !important;
    height: 72px;
    font-size: 28px !important;
    font-weight: 400 !important;
    line-height: 49.2px !important;
    color: #0a1126 !important;
    text-align: center;
  }
  .otp-input:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid #BCBFCC80 !important;
  }

  .requestagainPtag{
    font-family: Montserrat;
font-size: 14px;
font-weight: 400;
line-height: 22px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #9EA0A5;
margin: 0px !important;

  }
 .requestagainPtagspan{
    
    font-family: Montserrat-SemiBold;
font-size: 14px;
font-weight: 600;
line-height: 22px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FFFFFF;
margin: 0px !important; 
 cursor: pointer;
 }

 .requestagainPtagspan:hover{
    text-decoration: underline;
  
 }
 .NoCourse{
    margin: 0px !important;
    font-family: Montserrat-Bold;
    font-size: 28px;
    font-weight: 700;
    line-height: 30.8px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    
color: #FFFFFF;
}

 .SignUpMain1_2Btnnn{

    width: 100%;
height: 56px;
padding: 16px 28px 16px 28px;
border-radius: 16px;
background: #0F6FFF;
font-family: Montserrat-SemiBold;
font-size: 16px;
font-weight: 600;
line-height: 22px;
letter-spacing: -0.006em;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FFFFFF;
border: none;
margin-bottom: 20px !important;
}

.SignUpMain1_2Btnnn:hover{
    background: #FFFFFF;
    color: #000000;
}



.SignUpMain1_1ptagg{
    font-family: Montserrat-Bold;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
margin: 0px !important;
white-space: nowrap;
}


@media(max-width:1000px){
    .SignUpMain1_2Btnnn {
        width: 448px;

    }
    .SignUpMain111{
        width: 100%;
    }
}



@media(max-width:550px){

.SignUpMain1_1iconn{

}
.SignUpMain1_1ptagg {
    font-size: 28px;

}
.NoCourse{
font-size: 12px;
line-height: 16px;
}

.email-divvv {
width: 80px !important;
height: 80px !important;
}
.SignUpMain1_2Btnnn {
/* width: 100% ; */
width: 359px !important ;

}
.otpdiv22 {
gap: 13px !important;
width: 100% !important;
}

.SignUpMain1111 {
width: 100%;
padding: 30px !important;
}




}



@media(max-width:420px){

    .SignUpMain1_1ptagg {
        font-size: 24px;
    
    }
    .otpdiv22 {
        gap: 10px !important;
    }

    .email-divvv {

width: 70px !important;
height: 70px !important;


    }

   .SignUpMain1111 {
padding: 15px !important;
   }
   .SignUpMain1_2Btnnn {
width: 310px !important;

   }

   .requestagainPtag {
font-size: 12px;
   }
   .requestagainPtagspan {
    font-size: 12px;
   }

}




@media(max-width:350px){
    .SignUpMain1_1ptagg {
        font-size: 22px;
    }

    .otpdiv22 {
        gap: 4px !important;
    }

    .email-divvv {
        width: 65px !important;
        height: 65px !important;
    }
    .SignUpMain1_2Btnnn {
        width: 272px !important;
    }

}