.SignUpMain {
    /* max-width: 1440px; */
    width: 100%;
    /* height: 1024px; */
    background: #111214;
    display: flex;
    height: 100vh;
    overflow-y: hidden;
}
/*  */
.SignUpImg {
    /* width: 50%; */
    /* overflow-y: hidden; */
}

.SignUpMain11 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}



.SignUpMain1 {
    max-width: 420px;
  display: flex;
  flex-direction: column;
  /* padding: 141px 150px 141px 150px; */
  /* overflow-y: auto; */
  /* overflow-y: hidden; */
  overflow-y: auto;
  scrollbar-width: none !important;
  padding-top:50px ;
  padding-bottom:50px ;
}

.SignUpMain1_1{
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 65px;
    align-items: center;
}

.SignUpMain1_1ptag {
    font-family: Montserrat-Bold;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    margin: 0px !important;
    white-space: nowrap;
}



.SignUpMain1_2 {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 60px;
}


.SignUpMain1_2_first {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.SignUpMain1_2_first_P {
    font-family: Montserrat-Bold;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.005em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    margin: 0px !important;
}
.SignUpMain1_2_first_P1 {
    font-family: Montserrat-Bold;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.005em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    margin: 0px !important;
}
.SignUpMain1_2_first_Div {
    /* width: 420px; */
    width: 100%;
    height: 56px;
    padding: 16px;
    border-radius: 16px;
    background: #24262B;
    display: flex;
    gap: 8px;
    align-items: center;
}

.SignUpMain1_2_first_Div:focus-within {

    border: 1px solid #0F6FFF;
    box-shadow: 0px 0px 0px 4px #164BF940;
    background: #393C43;

}


.SignUpMain1_2_first_Div_T {
    font-family: Montserrat-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.006em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #D7D8D9;
    margin: 0px !important;
    background: transparent;
    border: none;
    width: 100%;
}


.SignUpMain1_2_first_Div_T:focus {
    outline: none;
}




.SignUpMain1_2_first_Div_T::placeholder {
    font-family: Montserrat-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.006em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #D7D8D9;
    margin: 0px !important;

}


.SignUpMain1_2Btn {

    width: 100%;
    min-height: 56px;
    padding: 16px 28px 16px 28px;
    border-radius: 16px;
    background: #0F6FFF;
    font-family: Montserrat-SemiBold;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.006em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    border: none;
}

.SignUpMain1_2Btn:hover {
  opacity: 0.7;
}

.image-icon {
    height: 24px;
    width: 24px;
}








@media(max-width:1000px) {

    .SignUpImg {
        display: none;
    }

    .SignUpMain11 {
        width: 100%;

        align-items: unset;
    }

    .SignUpMain1 {
        max-width: 100%;
        padding: 0px 100px;
    }

}

@media(max-width:950px) {
    .SignUpMain1 {

        padding: 30px 100px 30px 100px;
    }

    .SignUpMain1_2 {
        gap: 20px;
        margin-bottom: 45px;
    }

}

@media(max-width:700px) {
    .SignUpMain {
        height: 100vh;
    }
    .SignUpMain1_2_first_P1{
font-size: 14px;
    }
    .SignUpMain1 {

        padding: 30px 60px 30px 60px;
    }

    .SignUpMain1_1ptag {
        font-family: Montserrat-Bold;
        font-size: 27px;

    }

    .image-icon {
        height: 20px;
        width: 20px;
    }



}


@media(max-width:550px) {

    .SignUpMain1 {
        padding: 56px 30px 86px 30px;
    }
    .SignUpMain1_2_first_P1{
        font-size: 12px;
            }

}




@media(max-width:500px) {

    .SignUpMain1_1ptag {
        font-family: Montserrat-Bold;
        font-size: 20px;
    }


    .SignUpMain1_1 {
        gap: 10px;

    }

    .SignUpMain1_2_first_Div {
        height: 45px;
        padding: 12px;

    }

    .SignUpMain1_2Btn {
        height: 45px;
        padding: 12px;

    }

    .SignUpMain1_2 {
        gap: 18px
    }
    .image-icon{
        height: 20px;
        width: 20px;
    }
    .image-icon{
        height: 18px;
        width: 18px;
    }
    

}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}




@media(max-width:400px) {


    .SignUpMain1 {
        padding: 56px 25px 86px 25px;
    }

    .SignUpMain1_1ptag {

        font-size: 18px;
    }



    .SignUpMain1_2_first_P {
        font-size: 14px;
    }

    .SignUpMain {}

    .SignUpMain1_2_first_Div_T::placeholder {

        font-size: 12px;


    }

    .SignUpMain1_2Btn {
        font-size: 14px;
    }

}

@media(max-width:350px) {


    .SignUpMain1 {
        padding: 50px 25px;
    }

    .SignUpMain1_2 {
        gap: 15px;
    }

    .SignUpMain1_1 {

        margin-bottom: 40px;
    }

    .SignUpMain1_2 {

        margin-bottom: 50px;
    }
}