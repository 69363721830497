.funneel-hero-top{
    background: url('/public/Images/Fuunel-img/header-bg (1).png');
  
    background-repeat: no-repeat;
    background-size: 100% 100%;
     width: 100%;
 

}
.Funnel-hello-text{
    font-family: "Exo2-Regular";
font-size: 18px;
font-weight: 400;
line-height: 21.6px;
text-align: left;

color: #FFFFFF;
white-space: nowrap;
margin: 0px;

}
.Funnel_hello_text_container{
    border: 1px solid #0F6FFF4D;
    background: #0F6FFF1A;
   border-radius: 16px;
   padding: 15px;
   width: 236px;
}
.funnel_text_h_Global{
  
font-family: "Exo2-ExtraBold";
    font-size: 80px;
    font-weight: 800;
    line-height: 88px;
    
   
    
    margin: 0px !important;
    text-transform: uppercase;
    color: #FFFFFF; /* Base color of the text is white */
    text-shadow: 3px 3px 2.5px #0033CC,
      /* Blue shadow for the bottom */ 4px 4px 0px #000033; /* Dark blue shadow for deeper shading */

}
.funnel_span_forex{
    font-family: "Exo2-ExtraBold";
font-size: 80px;
font-weight: 800;
line-height: 88px;
text-align: left;



color: #0F6FFF;

}
.funnel-text-P-align{
    font-family: "Montserrat-Medium";
font-size: 22px;
font-weight: 500;
line-height: 30px;
text-align: left;

color: #FFFFFF;

}
.funnel-text-over-all{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.finnel-btn-align{
    font-family: "Exo2-Regular";
font-size: 20px;
font-weight: 400;
line-height: 24px;
text-align: center;
background: none;
border: none;
padding: 19px 0px;
width: 160px;
border-radius: 16px;
background: #0F6FFF;
color: #FFFFFF;

}

.finnel-btn-align:hover{
  background: #1856b3;
}
.finnel-btn-align2{
  font-family: "Exo2-Regular";
font-size: 20px;
font-weight: 400;
line-height: 24px;
text-align: center;
background: none;
border: 1px solid #FFFFFF;
padding: 19px 0px;
width: 160px;
border-radius: 16px;

color: #FFFFFF;

}

.btn-container-funel-both{
    display: flex;
    align-items: center;
    gap: 10px;
}
.funnel-text-btn-container{
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.video-container {
    width: 100%; /* Set to desired width */
    height: 380px; /* Set to desired height */
    display: flex;
    justify-content: center;
   border-radius: 40px;
    overflow: hidden;
  }
  
  .video-container video {
    width: 100%; /* Makes video responsive */
    height: 100%;
    object-fit: cover; /* Ensures the video fills the div */
  }
  .video-container video {
    width: 100%; /* Makes video responsive */
    height: 100%;
    object-fit: cover; /* Ensures the video fills the div */
  }  
  .video-funel-text-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 67px;
    padding: 110px 89px 180px 100px;

  }
  .funnel-text-trAined{
    font-family:"Exo2-Bold";
font-size: 30px;
font-weight: 700;
line-height: 36px;
text-align: center;

color: #FFFFFF;
margin: 0px !important;

  }
  .funel-logo-svg-contyainer {
    display: flex;
    align-items: center;
    justify-content: start; /* Align content to the start for smooth scrolling */
    gap: 80px;
    background: #0F6FFF33;
    padding: 10px 0px;
    overflow: hidden; /* Prevent overflow of the container */
    position: relative;
    margin-top: 40px !important;
  }
  
  .funel-logo-svg-contyainer img {
    animation: moveRightToLeft 10s linear infinite; /* Apply the animation */
  }
  
  @keyframes moveRightToLeft {
    from {
      transform: translateX(100%); /* Start offscreen to the right */
    }
    to {
      transform: translateX(-100%); /* Move offscreen to the left */
    }
  }
  .ytp-progress-bar{
    width: Fixed 540px !important;
height: 69px !important;


padding: 8px 10px 10px 10px;
gap: 10px;
border-radius: 80px !important;
background: #333A40 !important; 



  }
  @media  (max-width:1380px) {
    .video-funel-text-container{
        flex-direction: column;
        align-items: flex-start;
    }
    
  }
  @media  (max-width:768px) {
    .funnel_text_h_Global{
        font-size: 50px;
        line-height: 60px;
    }
    .funnel_span_forex{
        font-size: 50px;
        line-height: 60px;
    }
    .videoBox{
        width: 100%;
    }
    .video-funel-text-container{
        padding: 70px 25px;
        align-items: center;
    }

  }
  @media  (max-width:600px) {
    .funnel_text_h_Global{
        font-size: 40px;
    }
    .funnel_span_forex{
        font-size: 40px;
    }
    .funnel-text-P-align{
        font-size: 18px;
    }
    .btn-container-funel-both{
        flex-direction: column;

    }
    .finnel-btn-align{
        width: 100%;
    }
    .video-funel-text-container{
        gap: 30px;
    }
    .funnel-text-trAined{
        font-size: 20px;
    }
  }