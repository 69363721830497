.Twiter_mastry_Conatiner {
    display: flex;
    align-items: center;
    gap: 16px;
    border-bottom: 1px solid #24262B;
    width: 174px;
    margin: 0px;
}

.Twiter__OverAll_container {
    width: 100%;
    height: 100vh;
    background: #111214;
    padding: 0px 55px 0px 32px;
    padding-bottom: 50px;
    overflow: scroll;

}

.Insta_text_container {
    background: none;
    padding: 0px 4px 16px 4px;
    width: 92px;
    font-family: "Montserrat-Medium";
    font-size: 16px;
    font-weight: 500;
    line-height: 25.6px;
    letter-spacing: 0.005em;
    text-align: left;
    color: #9EA0A5;
    margin: 0px;
    cursor: pointer;
    background: none;
    border: none;

}

.Twiter_container {
    background: none;
    padding: 0px 4px 16px 4px;
    width: 66px;


}

.Instagram_text:hover {
    color: #FFFFFF;

}

.Insta_text_container:hover {
    color: #FFFFFF;

}

.twiter_Text__mastry {
    font-family: "Montserrat-SemiBold";
    font-size: 16px;
    font-weight: 600;
    line-height: 21.7px;
    text-align: left;

    color: #FFFFFF;
    margin: 0px;

}

.dollor_text {
    font-family: "Montserrat-SemiBold";
    font-size: 14px;
    font-weight: 600;
    line-height: 21.7px;
    text-align: left;
    color: #0F6FFF;
    margin: 0px;



}

.time_text {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.5px;
    text-align: left;
    color: #9EA0A5;
    margin: 0px;


}

.timer_container {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 0px;
}

.Twiter_mastry____container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card_container_twiter {
    display: flex;
    flex-direction: column;
    padding: 25px 24px 31px 24px;

    gap: 10px;
    margin: 0px;
}

.img-alignmnt {
    border-radius: 16px 16px 0px 0px;
    width: 255px;
    height: 166px;
    object-fit: cover;

}

.card_img_container {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 255px;

    background: #24262B;
    border-radius: 16px;
    margin: 0px;
}

.card_img_container:hover {
    opacity: 0.8;
}

.timer__containr {
    display: flex;
    align-items: center;
    gap: 3px;
}

.Cards___Twiter__alignmnt_Container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 23px;
    margin-top: 24px;
    padding-left: 25px;

}


.Insta_text_container.active {
    color: #ffffff;
}

.Insta_text_container.active {
    border-bottom: 1px solid #0F6FFF;
    /* Underline on active */

}

@media (max-width: 1165px) {
    .Cards___Twiter__alignmnt_Container {
        grid-template-columns: repeat(3, 1fr);
        /* 2 cards per row on smaller screens */
    }
}

@media (max-width: 768px) {
    .Cards___Twiter__alignmnt_Container {
        justify-content: center;
    }
}

@media (max-width: 630px) {
    .card_img_container {
        width: 100%;

    }
    .Cards___Twiter__alignmnt_Container{
        padding-left: 0px;
    }

    .img-alignmnt {
        width: 100%;
        height: 300px;
    }

    .Twiter__OverAll_container {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (max-width: 430px) {
    .img-alignmnt {
        width: 100%;
        height: 250px;
    }
}

@media (max-width: 390px) {
    .img-alignmnt {
        width: 100%;
        height: 220px;
    }
    .card_container_twiter{
        padding: 15px;
    }
}


@media (max-width: 340px) {
    .img-alignmnt {
        width: 100%;
        height: 180px;
    }
}