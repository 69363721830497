.SkainetPricing-main-container {
    padding: 100px 100px 65px 100px;
    width: 100%;

}

.SkainetPricing-main-container-cards-main {
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center; /* Centers cards horizontally */
}

.SkainetPricing-main-container-cards-sub {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 400px;
    height: 300.52px;
    padding: 50px 40px;
    border-radius: 30px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)),
        linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    border: 1px solid #ffffff33;
    backdrop-filter: blur(150px);
}

.SkainetPricing-main-container-cards-sub img {
    width: 30px;
}

.SkainetPricing-main-container-cards-sub-heading-text {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.SkainetPricing-main-container-cards-sub-heading-text h2 {
    font-family: Exo-Extra-Bold;
    font-size: 28px;
    font-weight: 800;
    line-height: 33.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    text-transform: uppercase;
    background: linear-gradient(169.97deg, #ffffff 8.65%, #cdcdcd 93.64%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0px;
}

.SkainetPricing-main-container-cards-sub-heading-text p {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0px;
    color: #ffffff;
}


/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
    .SkainetPricing-main-container {
        padding: 80px 50px 50px 50px;
    }

    .SkainetPricing-main-container-cards-sub {
        width: 350px;
        height: auto; /* Allow height to adjust dynamically */
        padding: 40px 30px;
    }

    .SkainetPricing-main-container-cards-sub-heading-text h2 {
        font-size: 24px;
        line-height: 30px;
    }

    .SkainetPricing-main-container-cards-sub-heading-text p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media (max-width: 768px) {
    .SkainetPricing-main-container {
        padding: 50px 20px 40px 20px;
    }

    .SkainetPricing-main-container-cards-sub {
        width: 100%; /* Full width for smaller screens */
    }

    .SkainetPricing-main-container-cards-sub-heading-text h2 {
        font-size: 20px;
        line-height: 26px;
    }

    .SkainetPricing-main-container-cards-sub-heading-text p {
        font-size: 12px;
        line-height: 18px;
    }
}

@media (max-width:480px) {
    .skainet-pricing-plan-cards-main {
        flex-direction: column !important;
        gap: 40px !important;
    }
    .skainet-pricing-plan-cards-main{
        justify-content: center !important;
        align-items: center !important;
    }
    .SkainetPricing-main-container {
        padding: 30px 13px 30px 13px;
    }
    .skainet-pricing-plan-cards-sub{
        padding-right: 20px !important;
    }
    .skainet-pricing-plan-cards-sub{
        margin-top: 0px !important;

    }
    .SkainetPricing-main-container-cards-sub {
        padding: 30px 20px;
    }

    .SkainetPricing-main-container-cards-sub-heading-text h2 {
        font-size: 18px;
        line-height: 22px;
    }

    .SkainetPricing-main-container-cards-sub-heading-text p {
        font-size: 11px;
        line-height: 16px;
    }

    .SkainetPricing-main-container-cards-sub img {
        width: 25px;
    }
    .skainet-pricing-plan-cards-sub{
        height: unset;
        width: unset;
    }
    .doller-per-members h2 {
        font-size: 50px !important;
    }
    .doller-per-members p {
        font-size: 15px !important;
    }
    .pricing-cards-bottom-context-plan-image-btn h2{
        font-size: 14px;
    }
    .Coming-Soon{
        font-size: 9px !important;
    }
    .skainet-pricing-plan-cards-sub{
        width: unset !important;
        height: unset !important;
    }
    .skainet-pricing-plan-heading-color h2 {
        font-size: 35px !important;
        line-height: 35px !important;
    }
    .skainet-pricing-plan-heading-color-para p {
        font-size: 13px !important;
        line-height: 23px !important;
    }
    .skainet-pricing-plan-heading-color{
        margin-top: 50px !important;
    }
    .skainet-pricing-plan-cards-main{
        margin-top: unset !important;

    }
    .skainet-pricing-plan-cards-sub{
        margin-top: 0px !important;

    }
    .skainet-pricing-plan-cards-main {
        gap: 40px;
    }
}


.skainet-pricing-plan-heading-color{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 104px;
    gap: 20px;
}
.skainet-pricing-plan-heading-color h2{
    font-family: Exo-Extra-Bold;
    font-size: 60px;
    font-weight: 800;
    line-height: 66px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0px !important;
    text-transform: uppercase;
    color: #ffffff; /* Base color of the text is white */
    text-shadow: 3px 3px 2.5px #0033cc,
      /* Blue shadow for the bottom */ 4px 4px 0px #000033; /* Dark blue shadow for deeper shading */
}
.skainet-pricing-plan-heading-color-para p{
    font-family: Montserrat;
font-size: 22px;
font-weight: 500;
line-height: 32px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FFFFFF;
margin: 0px;
}



.skainet-pricing-plan-cards-sub{
 width: 394.56px;
height: 854.43px;
border-radius: 32px;
background: #FFFFFF0D;
border: 1px solid #FFFFFF99;
padding: 55px 37px 30px 47px;
}
.basic-btn{
    max-width: 100%;
padding: 6px 15px 6px 15px;
border-radius: 6px;
border: none;
background: #F1F1F1;
font-family: Exo;
font-size: 16px;
font-weight: 600;
line-height: 30px;
letter-spacing: 0.01em;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #00061B;
text-align: center;
margin: 0px;
}
.skainet-pricing-plan-cards-sub p{
    font-family: Montserrat;
font-size: 16px;
font-weight: 500;
line-height: 19.5px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FFFFFF;
margin: 15px 0px 0px 0px;
}
.center-line-pricing-btn img{
   width: 100%;
display: flex;
margin-top: 28px ;
}
.doller-per-members{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
.doller-per-members h2{
    font-family: Exo;
font-size: 72px;
font-weight: 600;
line-height: 79.2px;
letter-spacing: -0.5px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #0F6FFF;
margin: 0px;
}
.doller-per-members p{
    font-family: Montserrat;
font-size: 16px;
font-weight: 700;
line-height: 26px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FFFFFF;
margin: 0px;
}
.center-line-pricing-btn2 img{
    width: 100%;
 display: flex;
 margin-top: 30px ;
 }
 .pricing-cards-bottom-context-plan{
    display: flex;
    flex-direction: column;
 }
 .pricing-cards-bottom-context-plan-image-btn{
    display: flex;
    align-items: center;
    
 }

 .pricing-cards-bottom-context-plan-image-btn{
    display: flex;
    align-items: center;
    gap: 5px;
  
 }
 .pricing-cards-bottom-context-plan-image-btn h2{
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color:#FFFFFF ;   
    margin: 0px;
 }
 .pricing-cards-bottom-context-plan{
    display:flex ;
    flex-direction: column;
    gap: 15px;
    margin-top: 30px;
 }
.skainet-pricing-plan-cards-main{
    display: flex;
    margin-top: 80px;
    justify-content: center;
    width: 100%;
    gap: 27px;
    flex-wrap: wrap;
}
.Coming-Soon{
width: 79px;
display: flex;
align-items: center;
justify-content: center;
height:18px;
padding: 5px;
border-radius: 4px;
background: #FFFFFF12;
font-family: Montserrat;
font-size: 10px;
font-weight: 400;
line-height: 26px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FFFFFF;
margin: 0px;
text-align: center;
}

.free-trail-main-btn{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 29px;
}
.free-trail-main-btn button{
border-radius: 16px;
background: #0F6FFF;
border: none;
font-family: Exo;
font-size: 20px;
font-weight: 500;
line-height: 24px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
margin: 0px;
color: #FFFFFF;
display: flex;
justify-content: center;
align-items: center;
width: 273px;
height: 64px;
}

.Payment-Methods-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 9px;
    margin-top: 60px;
}
.Payment-Methods-main-container h2{
    font-family: Exo;
    font-size: 25px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin:0px;
    color: #FFFFFF;
}
.Payment-Methods-main-container p{
    font-family: Montserrat;
font-size: 16px;
font-weight: 400;
line-height: 26px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
margin:0px;
color: #FFFFFF;
}
@media (max-width:768px) {
    .skainet-pricing-plan-cards-main{
        justify-content: center;
        gap: 20px;
    }
    .skainet-pricing-plan-cards-sub{
margin-top: 20px !important;
    }
    
}
@media (max-width:580px) {
    .skainet-pricing-plan-cards-main{
        justify-content: center !important;
        margin-top: 20px !important;
    }
    .skainet-pricing-plan-cards-sub{
        width: 290px !important;
        padding: 20px 16px !important
    }
    .pricing-cards-bottom-context-plan-image-btn h2{
        font-size: 14px !important;
    }
    .Coming-Soon{
       
        font-size: 8px !important;
    }
    .my-best-part-to-move{
        width: 87px;
    }

.pricing-cards-bottom-context-plan-image-btn h2 {
    line-height: 14px;
    font-size: 11px !important;
} 
.free-trail-main-btn button{
    font-size: 14px !important;
    height: 53px !important;
}
}


.SkainetPricing-main-container {
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .SkainetPricing-background-blur {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px; /* Adjust width */
    height: 400px; /* Adjust height */
    background: #022EEB;
    opacity: 0.5; /* Adjust opacity */
    border-radius: 50%;
    filter: blur(150px); /* Adjust blur level */
    z-index: 0;
  }
  .free-trail-main-btn button:hover{
    background: #FFFFFF;
    color: #000619;
    ;
  }
  .free-trail-main-btn-new{
    border-radius: 16px;
    background:#FFFFFF ;
    border: none;
    font-family: Exo;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0px;
    color: #000619;
    display: flex;
    margin-top: 29px;

    justify-content: center;
    align-items: center;
   
}
  .btn-styl{
    background: none;
    border: none;
    width: 273px;
    height: 64px;
  }
  .free-trail-main-btn-new:hover{
    background: #0F6FFF;
    color: #FFFFFF;
  }
  .btn-styl:hover{
    color: #FFFFFF;

  }
