/* .css-1ju65hq-MuiDrawer-docked{
  z-index: 5 !important;
} */

/* After build Styling*/
p {
  margin: 0px !important;
}

.Mui-selected {
  color: #0f68ff !important;
  background: none !important;
  display: flex;
  align-items: center;
  gap: 1px;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important;
  /* 22.4px */
  border-radius: 8px !important;
  transition: border-radius 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s !important;
}

.menu-items {
  color: var(--Grey-500---Primary, #667085) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important;
  /* 22.4px */
}

.css-1ju65hq-MuiDrawer-docked .MuiDrawer-paper {
  justify-content: space-between;
}
.kuchdaydo{
  min-width: 33px !important;
}
.Avatar-div {
  /* margin-top: 30%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Avatar-div-mobile {
  margin-top: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*dropdown avatar-top */

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  gap: 10px;
}

.css-9mgopn-MuiDivider-root {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;

  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  border: none !important;
}

/* .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  top: 53px !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px !important;
} */

.List-div {
  margin-top: 20% !important;

  padding-left: 12px !important;
  padding-right: 8px !important;
  /* color: var(--Grey-500---Primary, #667085) !important; */

  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important;
  /* 22.4px */
}

/* icons */
.css-cveggr-MuiListItemIcon-root {
  min-width: auto !important;
}

/* .css-h4y409-MuiList-root {
  padding-top: 32px !important;
} */
/* icon-label */
.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
  /* padding-top: 12px !important;
  padding-bottom: 12px !important; */
  gap: 12px !important;
}

.drawr-option-text1 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: linear-gradient(180deg, #000000 0%, #323232 100%) !important;
  margin: 0px !important;
}

.drawr-option-text12 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.4px;
  text-align: left;
  color: #bcbfcc !important;
  margin: 0px !important;
}

.list-icons {
  min-width: 24px !important;
  width: 24px !important;
  height: 24px !important;
  font-size: 24px !important;
  color: var(--Grey-500---Primary, #667085) !important;
}

.Logout-div {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  padding-left: 27px;
  margin-top: 30px !important;
  margin-bottom: 32px !important;
}

.Logout-txt {
  font-family: Montserrat-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  color: #F04A4A
}

.items-main-heading {
  padding-left: 16px;
  color: #1e1e2480 !important;

  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 19.2px;

  padding-bottom: 8px;
  text-transform: uppercase;
}

/* .muiltr-1jkhpm7.active {
  color: rgb(255, 255, 255);
  pointer-events: none;
  transition: border-radius 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  background-color: rgba(255, 255, 255, 0.1) !important;
} */

.css-1vz77qy-MuiTypography-root {
  font-family: Montserrat-Medium !important;
  font-size: 14px !important;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
  color: #FFFFFF
}

.css-i4bv87-MuiSvgIcon-root {
  color: #FFFFFF !important
}

.toggle {
  margin-left: -20px !important;
}

.list-item {
  color: #bcbfcc !important;

  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important;
  /* 22.4px */
  display: flex;
  align-items: left;
  gap: 12px;
}

.list-item-active {
  color: transparent !important;
  background: linear-gradient(180deg, #000000 0%, #323232 100%) !important;
  background-clip: text !important;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important;
  /* 22.4px */
  border-radius: 8px;
  /* padding-top: 12px !important;
  padding-bottom: 12px !important; */
  /* background-color: var(--Blue-Text, #0F68FF) !important; */
  transition: border-radius 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s !important;
}

.list-item:hover {
  color: #000000 !important;
  border-radius: 8px !important;
  /* background-color: var(--Blue-Text, #0f68ff) !important; */
}

.css-18sg6k4-MuiPaper-root-MuiDrawer-paper {
  /* background-color: #111827 !important; */
  background-color: #fff !important;
}

/* .css-ciy9n4-MuiPaper-root-MuiAppBar-root{
  z-index: -1 ;
} */

.css-mrxozk-MuiDrawer-docked .MuiDrawer-paper {
  width: 280px;
  box-sizing: border-box;
  z-index: 1 !important;
  justify-content: space-between !important;
}

/* List -txt / sidebar*/

.css-10hburv-MuiTypography-root {
  margin: 0;

  display: block;
  font-size: 14px !important;

  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important;
}

.drawer__main__container1 {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between;
  height: 100vh;
}


.css-gkgj6z-MuiButtonBase-root-MuiListItemButton-root .MuiListItemText-primary {
  font-family: Montserrat-Medium !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 22.4px !important;
  text-decoration-skip-ink: none;
  color: #FFFFFF !important
}

.css-1eljb98-MuiButtonBase-root-MuiListItemButton-root .MuiListItemText-primary {
  font-family: Montserrat-Medium !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 22.4px !important;
  text-decoration-skip-ink: none;
  color: #FFFFFF !important
}

.css-ds6ijv-MuiButtonBase-root-MuiListItemButton-root .MuiListItemText-primary {
  font-family: Montserrat-Medium !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 22.4px !important;
  text-decoration-skip-ink: none;
  color: #FFFFFF !important
}

.css-tjzcy6-MuiButtonBase-root-MuiListItemButton-root .MuiListItemText-primary {
  color: #FFFFFF !important;
}

.css-19lg2kl-MuiDrawer-docked .MuiDrawer-paper {
  display: flex !important;
  justify-content: space-between !important;
}

.css-edh7jl-MuiButtonBase-root-MuiListItemButton-root .MuiListItemText-primary {
  font-family: Montserrat-Medium !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 22.4px !important;
  text-decoration-skip-ink: none;

  color: #FFFFFF !important
}

.css-ivnu1y-MuiPaper-root-MuiAppBar-root {
  z-index: -1 !important;
  background-color: #fff !important;
}

/* Menu Icon */
.css-134qg7o-MuiButtonBase-root-MuiIconButton-root {
  color: black !important;
}

.css-1dubi9j-MuiButtonBase-root-MuiListItemButton-root .MuiListItemText-primary {
  font-family: Montserrat-Medium !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 22.4px !important;
  text-decoration-skip-ink: none;

  color: #FFFFFF !important
}

.css-hyum1k-MuiToolbar-root {
  background: #fff !important;
  border-bottom: 1px solid var(--grey-100-primary-outline-color, #f0f1f3) !important;
  box-shadow: none !important;
}

.css-i1kkii-MuiPaper-root-MuiAppBar-root {
  box-shadow: none !important;
  z-index: 2 !important;
}

.css-1s0rz8x-MuiListItemIcon-root {
  min-width: 33px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.css-q2rdm3 {
  height: 100vh !important;
  justify-content: space-between !important;
}

.css-3x305n-MuiDrawer-docked .MuiDrawer-paper {
  justify-content: space-between !important;
}

/* toolbar*/
.css-55u6o0-MuiPaper-root-MuiAppBar-root {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: fixed;
  z-index: 1100;
  top: 0;
  left: auto;
  right: 0;

  color: #fff;
  -webkit-transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  width: calc(100% - 280px);
  margin-left: 280px;
}

/* left side toolbar*/

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  /* background-color: #111827 !important; */
  background-color: #fff !important;
  color: #fff !important;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  z-index: 1200;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  outline: 0;
  left: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

/* Toolbar css */
.css-15075hz-MuiPaper-root-MuiAppBar-root {
  z-index: 2 !important;
}

.css-ciy9n4-MuiPaper-root-MuiAppBar-root {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: fixed;
  z-index: 1100;
  top: 0;
  left: auto;
  right: 0;

  color: #fff;
  -webkit-transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.css-jzk4qw-MuiPaper-root-MuiAppBar-root {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: fixed;
  z-index: 1100;
  top: 0;
  left: auto;
  right: 0;
  /* background-color: #111827 !important; */
  background-color: #fff !important;
  color: #fff;
  -webkit-transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  width: calc(100% - 240px);
  margin-left: 240px;
}

.NoCourse {
  padding-left: 29px;
  margin: 0px !important;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
}

/* menu bar and profile icon */
.css-hyum1k-MuiToolbar-root {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 56px;
  justify-content: space-between;
}

.Navbar-Profile {
  border: 2px solid #0F6FFF;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  object-fit: contain;
  background-color: #393C43;
}

.btn-wallet {
  border-radius: 8px;
  background: var(--Blue-Text, #0f68ff);
  display: flex;
  height: 34px;
  padding: 14px 12px;
  justify-content: center;
  align-items: center;
  border: none;
  color: var(--Black-0---Primary, #fff);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  /* 18.2px */
}

.vertical-line {
  border-right: 1px solid #f0f1f3;
  height: 20px;
}

.setting-notify {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  /* width: 100%; */
}

.btn-search {
  border: none;
  border-radius: 8px;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3);
  background: var(--Black-0---Primary, #fff);
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Grey-500---Primary, #667085);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 22.4px */
  letter-spacing: 0.07px;
}

/* search -bar */

.css-1mgg4b8-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px !important;
}

.Avatar-top-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  /* margin-right: 16px;
  margin-left: 16px; */
  cursor: pointer;
 width: 100%; 
}

.notify-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 100%; */
  gap: 24px;
}

.innertext-options {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.toptext {
  font-family: Montserrat !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 36px !important;
  letter-spacing: -0.01em !important;
  text-align: left !important;
  color: #1e1e24 !important;
  margin-left: 20px;
}

.topbutton {
  width: 157px;
  height: 39px;
  background: linear-gradient(180deg, #000000 0%, #323232 100%);
  border-radius: 8px;
  font-family: MontserratMedium !important;
  font-size: 14px !important;
  font-weight: 500;
  line-height: 20.3px;
  text-align: center;
  color: #ffffff;
}

.label-after-accordian-is-fixed-now {
  color: brown !important;
  font-family: "Montserrat-Medium" !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.css-rizt0-MuiTypography-root {
  font-family: "Montserrat-Medium" !important;
}

.Logout-bottom-side-part-main {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 24px !important;
}

.Logout-bottom-side-part-main p {
  font-family: "Montserrat-Medium" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 16.8px !important;
  text-align: left !important;
  text-underline-position: from-font !important;
  text-decoration-skip-ink: none;
  color: #F04A4A;
  margin: 0px !important;
}

.css-cokf1l-MuiListItemIcon-root {
  min-width: 37px !important;
}


@media (max-width: 600px) {
  .css-1pfgv65 {
    overflow: hidden !important;
  }
  .header-main{
    padding-left: 0px !important;
  }
  .css-177wmp6-MuiDrawer-docked .MuiDrawer-paper {
    width: 222px !important;
  }
}