.TrustedAgency-main-container{
    background-color: #00061B;
    width: 100%;
    padding: 120px 100px;
}
.TrustedAgency-main-container-button-and-text{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.TrustedAgency-main-container-button-and-text-heading{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.TrustedAgency-main-container-button-and-text-heading h2{
    font-family: Exo-Extra-Bold;
    font-size: 60px;
    font-weight: 800;
    line-height: 64px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0px !important;
    text-transform: uppercase;
    color: #ffffff; /* Base color of the text is white */
    text-shadow: 3px 3px 2.5px #0033cc,
      /* Blue shadow for the bottom */ 4px 4px 0px #000033; /* Dark blue shadow for deeper shading */
}
.trusted-btn{
width: 133px;
height: 54px;
border-radius: 16px;
background: #0F6FFF;
border: none;
display: flex;
align-items: center;
justify-content: center;
font-family: Exo;
font-size: 20px;
font-weight: 400;
line-height: 24px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FFFFFF;
margin: 0px;
}

.agency-members {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 60px;
    position: relative; /* Needed for the pseudo-element to be positioned properly */
    z-index: 1; /* Ensure this section stays above the background */
}

/* Background blur effect */
.agency-members::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 90%;
    background: #022EEB;
    filter: blur(50px);
    z-index: -1; /* Ensures it stays behind the content */
    border-radius: 20px; /* Optional: rounded edges for aesthetics */
    opacity: 0.4; /* Adjust transparency if needed */
}

/* Individual member container */
.member {
    position: relative;
    overflow: hidden;
}

.member img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container */
}

/* Overlay text */
.info {
    position: absolute;
    bottom: 19px;
    left: 30px;
    color: #fff; /* White text for visibility */
}

.info h3 {
    font-family: Exo-Bold;
    font-size: 35px;
    font-weight: 700;
    line-height: 42px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    background: linear-gradient(169.97deg, #FFFFFF 8.65%, #CDCDCD 93.64%);
    -webkit-background-clip: text; /* Clip gradient to text (for WebKit browsers) */
    -webkit-text-fill-color: transparent; /* Makes text transparent to reveal gradient */
    background-clip: text; /* Standard property for modern browsers */
    color: transparent; /* Fallback in case gradient is unsupported */
    margin: 0px !important;
}

.info p {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.94px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF; 
    margin: 0px !important;   
}
@media (max-width:1050px) {
    .TrustedAgency-main-container-button-and-text{
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 30px;
    }
    .TrustedAgency-main-container{
        padding: 80px 50px;
    }
    .info p {
        font-size: 15px;
    }
    .info h3{
        font-size: 20px;
    }
    .agency-members{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width:600px) {
    .agency-members{
        grid-template-columns: repeat(1, 1fr);
    }
    .TrustedAgency-main-container-button-and-text-heading h2 {
        font-size: 30px;
        line-height: 40px;
    }
    .TrustedAgency-main-container{
        padding: 40px 25px;
    }
    
}