.landingPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden !important;
}

.landingPage__topgradient {
  position: relative;
  overflow: hidden;
  /* background-image: url("/public/Images/LandingPage/Rectangle\ 4.png");
  background-repeat: no-repeat;
  background-position: top center; */
  /* background-size: 100% 281px; */
}

.blurnessDiv1 {
  position: absolute;
  top: -16%;
  left: 18%;
  width: 70%;
  height: 21%;
  background-color: #0F6FFF !important;
  filter: blur(200px);
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  z-index: -1;

}




.Landingpage-div1Bottom {
  position: relative;
  /* background-image: url("/public/Images/LandingPage/Rectangle\ 3.png");
  background-position: bottom center;
  background-repeat: no-repeat; */
  background-size: 100% 281px;
}

.Blurness {
  position: absolute;
  top: 60%;
  left: 20%;
  width: 60%;
  height: 30%;
  background-color: #0F6FFF !important;
  filter: blur(112px);
  border-radius: 50%;
  z-index: -1;

}



.globaldivleft {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.div1left1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.div1left11 {
  padding: 15px;
}


.globalPara {
  margin: 0px !important;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;

}

.globalPara2 {
  /* padding-top: 3px; */
  margin-top: 20px !important;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0;
  text-align: center;
}


.globaldivright {
  width: 80%;
  margin-top: 46px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.videoBox {
  border-radius: 40px;
  /* height: 380px; */

}

.globaldiv1Top {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}

.helowediv {
  display: flex;
  padding: 15px;
  border-radius: 20px;
  background-color: rgba(16, 16, 17, 0.05) !important;
}

.globalHeading {
  text-align: center;
  font-size: 80px;
  /* Matches your font size */
  line-height: 75px;
  /* Line spacing */
  font-weight: 800;
  /* Bold text */
  text-transform: uppercase;
  /* Capital letters */
  color: #FFFFFF;
  /* White text */
  font-family: "Exo 2", sans-serif;
  /* Use specified font */
  text-shadow: 0px 1px 0px #00061B, 2.3px 2.2px 0px #0F6FFF;
  margin-bottom: 0;
}

.globalHeadingBlue {
  color: #0F6FFF;
  /* Bright blue color */
  text-shadow: 0px 3px 0px #00061B, 1.8px 2px 0px #0F6FFF;
}


.GlobalDiv1 {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  padding-left: 100px;
  padding-right: 89px;
  padding-top: 101px;
  position: relative;

}

.helowe {
  font-family: "Exo 2" !important;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  line-height: 21.6px;
  font-weight: 400;
  margin-bottom: 0;
}

.videoPlayer {
  height: 100%;
  width: 100%;
  border-radius: 40px;
}

.div1leftButtons {
  margin-top: 20px !important;
  width: 100% !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  display: flex;
  gap: 10px;
}

.startNowButton {
  height: 64px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 16px !important;
  background: rgba(15, 111, 255, 1) !important;
  border: none !important;
  padding: 25px 45px !important;
  font-family: "Exo 2" !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: rgba(255, 255, 255, 1) !important;
  cursor: pointer;
  /* Changes cursor to pointer on hover */
  transition: background 0.3s ease;
}

.startNowButton:hover {
  background: rgba(10, 80, 200, 1) !important;
  /* Darker shade of blue */
}

.LearnMoreButton {
  white-space: nowrap !important;
  height: 64px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 16px !important;
  background: none !important;
  border: 1px solid rgba(255, 255, 255, 1) !important;
  font-family: "Exo 2" !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: rgba(255, 255, 255, 1) !important;
  padding: 25px 45px !important;
  transition: all 0.3s ease;

  /* Smooth hover effect */
}

.LearnMoreButton:hover {
  background: rgba(255, 255, 255, 0.1) !important;
  /* Slightly darken background */
  border: 1px solid rgba(200, 200, 200, 1) !important;
  /* Darker border */
  color: rgba(200, 200, 200, 1) !important;
  /* Slightly darker text */
}

.whitelinesdiv {
  height: 200px;
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

}

.TopLinePara {
  font-family: "Exo 2";
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  background: linear-gradient(169.97deg, #FFFFFF 8.65%, #CDCDCD 93.64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0;
  text-transform: uppercase;



}

.TopLine {
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  height: 75px;
  width: 100%;
  overflow: hidden !important;
  /* Prevent overflow of the container */
  position: relative;
  transform: rotate(1deg);
  /* Tilt the div slightly */
  transform-origin: left;
}

.TopLine img {
  /* animation: moveRightToLeft 20s linear infinite; */
}

.bottomLine {
  background-color: rgba(19, 64, 166, 1);
  height: 75px;
  transform: rotate(-1deg);
  /* Tilt the div slightly */
  transform-origin: right;
  overflow: hidden !important;


}

.scrolling-container {
  width: max-content;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 27.66px;
  animation: scrollLeft 200s linear infinite;
}

@keyframes scrollLeft {
  from {
    transform: translateX(100);
    /* Start from the original position */
  }

  to {
    transform: translateX(-50%);
    /* Move out of the view */
  }
}

.bottomLine img {
  /* animation: moveRightToLeft 20s linear infinite; Apply the animation */
}




.div2 {
  padding-top: 120.66px;
  padding-left: 100px;
  padding-right: 99.31px;
  padding-bottom: 124px;
  display: flex;
  flex-direction: column;
  gap: 80px;

}

.imageinstax {
  width: 600.69px;
  height: 573.91px;
  margin-left: 50px;

}

.wrappingDiv2 {
  position: relative;
  /* overflow: hidden; */
}

.blurdiv2 {
  position: absolute;
  /* margin-left: -50px; */
  top: 39%;
  left: -29%;
  width: 49%;
  height: 30%;
  background-color: #022EEB !important;
  filter: blur(355px);
  border-radius: 50%;
  z-index: -1;
}

.div2TopHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Div2Heading {
  /* text-align: center; */
  font-family: "Exo 2";
  /* Ensure proper font loading */
  font-size: 50px;
  /* Adjusted size */
  line-height: 55px;
  /* Line spacing for alignment */
  font-weight: 800;
  /* Bold text */
  text-transform: uppercase;
  /* Capital letters */
  color: #FFFFFF;
  /* White text */
  text-shadow: 0px 1px 0px #00061B, 2.3px 2.2px 0px #0F6FFF;
  margin: 0px !important;
}

.div2courses {
  color: #0F6FFF;
  /* Bright blue color */
  text-shadow: 0px 3px 0px #00061B, 2.3px 2px 0px #0F6FFF;
}


.div2topPara {
  padding-left: 10.5px;
  padding-right: 10.5px;
  margin:20px 0px 0px 0px !important;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.94px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}

.div2below {
  display: flex;
  gap: 40px;

}

.div2Belowleft {

  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;

}

.div2Box {
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 50px;
  padding-right: 37px;
  border-radius: 16px;
  backdrop-filter: blur(150px) !important;
  gap: 20px;

}

.InstaGramMastryImage {
  height: min-content;
}

.Div2Box1Heading {
  font-family: "Exo 2";
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
  text-transform: uppercase;
  background: linear-gradient(169.97deg, #FFFFFF 8.65%, #CDCDCD 93.64%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0;
}

.div2box1para {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: white;
  margin-bottom: 0;
}

.div2Belowbox {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Buydiv2BoxButton {
  margin-top: 10px !important;
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 12px;
  height: 35px;
  width: 76px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: none !important;
  font-family: "Exo 2" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 19.2px !important;
  color: rgba(255, 255, 255, 1) !important;
  transition: all 0.3s ease;
  /* Smooth hover effect */
}

.Buydiv2BoxButton:hover {
  background: rgba(255, 255, 255, 0.1) !important;
  /* Slightly darken background */
  border: 1px solid rgba(200, 200, 200, 1) !important;
  /* Darker border */
  color: rgba(200, 200, 200, 1) !important;
  /* Slightly darker text */
}

.div3 {
  /* background: rgba(15, 111, 255, 0.2); */
  background-color: #031B4A;
  padding-top: 120px;
  padding-left: 100px;
  padding-bottom: 120px;
  padding-right: 100px;
  display: flex;
  flex-direction: column;
  gap: 61px;
}

.div3Top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.div3Topleft {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Viewdiv3Button {
  border-radius: 16px;
  height: 64px;
  width: 133px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: rgba(15, 111, 255, 1) !important;
  border: none !important;
  font-family: "Exo 2" !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: rgba(255, 255, 255, 1) !important;
  transition: background 0.3s ease;
}

.Viewdiv3Button:hover {
  background: rgba(10, 80, 200, 1) !important;
  /* Darker shade of blue */
}

.contentpara {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 0;
  color: rgba(255, 255, 255, 1);
}

.div3below {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
}

.image-container {
  position: relative;
  /* Position relative to parent */
  height: 100%;

  overflow: hidden;
  /* Hide any content overflow */
}

.simpleImage {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  /* Smooth hover effect */
}

.image-card {
  height: 422px;
  width: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px !important;
  transition: opacity 0.5s ease 0.5s, transform 0.5s ease 0.5s;
  /* transition: background-image 0.3s ease; */
  padding-top: 23px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  /* display: flex; */
  display: none;
  justify-content: space-between;
  flex-direction: column;
  opacity: 0;
  /* Start hidden */
  transform: scale(0.95);
  position: relative;
}

.image-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  opacity: 0;

  transition: opacity 0.5s ease 0.5s;

}


.bg-image-1 .image-card {
  background-image: url("/src/assets/greendivbox.png") !important;
  width: 398px !important;
}

.bg-image-2 .image-card {
  background-image: url("/src/assets/brownDivBox.png") !important;
  width: 398px !important;
}


.bg-image-3 .image-card {
  background-image: url("/src/assets/image_3.png") !important;
  width: 398px !important;
}

.bg-image-4 .image-card {
  background-image: url("/src/assets/4thDivBox.png") !important;
}

.bg-image-5 .image-card {
  background-image: url("/src/assets/5thDivBox.png") !important;
}

.image-card1 {
  height: 422px;
  width: 400px;
  background-image: url("/src/assets/greenRobotFull.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px !important;
  transition: background-image 0.5s ease, opacity 0.5s ease;
  padding-top: 23px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 17px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

}

.simpleImage {
  display: block;
}

.image-card1 {
  display: none;
  transition: all 0.3s ease 0.2s;
}

.image-card {
  display: none;
  /* Initially hidden */
  transition: all 0.3s ease 0.2s;
}

.image-container1:hover .simpleImage {
  display: none;
}

.image-container:hover .simpleImage {
  display: none;
  transition-delay: 1s;
  transition: opacity 0.3s ease 0.2s, visibility 0.3s smooth 1s;
}

.image-container:hover .image-card {
  /* display: block;
  background-image: inherit;
  transition-delay: 1s;
  transition: opacity 0.5s ease 0.5s, visibility 0.5s smooth 1s; */

  display: flex;
  background-image: inherit;
  opacity: 1;
  /* Fade-in effect */
  transform: scale(1);
  /* Scale to normal */
  transition-delay: 1s;
  /* Delay for transition start */

}

.div3__blurness__container {
  position: absolute;
  left: -12%;
  width: 64%;
  height: 61%;
  background-color: #022EEB !important;
  filter: blur(355px);
  border-radius: 50%;
  z-index: -1;
}

.div3 {
  /* background: rgba(15, 111, 255, 0.2); */
  background: rgba(15, 111, 255, 0.2);
  padding-top: 120px;
  padding-left: 100px;
  padding-bottom: 120px;
  padding-right: 100px;
  display: flex;
  flex-direction: column;
  gap: 61px;
}

.div3Top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.div3Topleft {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contentpara {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #FFFFFF;
}

.courses___cards__main__container {
  display: flex;
  gap: 20px;
}

.instgaram___mystery__card__courses {
  padding: 60px 35px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
  border: 1px solid #ffffff1a;
  backdrop-filter: blur(150px);
  border-radius: 20px;
}

.instagram___mystery__course__text__container {
  display: flex;
  flex-direction: column;
  gap: 10px
}

.instagram___mystery__course__text {
  font-family: "Exo 2";
  font-size: 22px;
  font-weight: 800;
  line-height: 26.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background: linear-gradient(169.97deg, #FFFFFF 8.65%, #CDCDCD 93.64%);
  background-clip: text;
  color: transparent;
  max-width: 225px;
  text-transform: uppercase;
}

.instagram___mystery__course__paragraph {
  font-family: Montserrat-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #FFFFFF;
  max-width: 225px;
}

.twitter___mystery__card__courses {
  padding: 60px 35px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
  border: 1px solid #ffffff1a;
  backdrop-filter: blur(150px);
  border-radius: 20px;
}

.twitter___mystery__course__text__container {
  display: flex;
  flex-direction: column;
  gap: 10px
}

.twitter___mystery__course__text {
  font-family: "Exo 2";
  font-size: 22px;
  font-weight: 800;
  line-height: 26.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background: linear-gradient(169.97deg, #FFFFFF 8.65%, #CDCDCD 93.64%);
  background-clip: text;
  color: transparent;
  max-width: 225px;
  text-transform: uppercase;
}

.twitter___mystery__course__paragraph {
  font-family: Montserrat-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #FFFFFF;
  max-width: 225px;
}

.coaching___program__card__courses {
  padding: 60px 35px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
  border: 1px solid #ffffff1a;
  backdrop-filter: blur(150px);
  border-radius: 20px;
}

.comming__soon__container {
  background: #0F6FFF;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 20px;
  padding: 7px 10px;
  position: absolute;
  top: 0%;
  right: 0%
}

.comming__soon__text {
  font-family: "Exo 2";
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;
  text-align: left;
  background: linear-gradient(169.97deg, #FFFFFF 8.65%, #CDCDCD 93.64%);
  background-clip: text;
  color: transparent;
}

.coaching__course__text__container {
  display: flex;
  flex-direction: column;
  gap: 10px
}

.coaching__course__text {
  font-family: "Exo 2";
  font-size: 22px;
  font-weight: 800;
  line-height: 26.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background: linear-gradient(169.97deg, #FFFFFF 8.65%, #CDCDCD 93.64%);
  background-clip: text;
  color: transparent;
  max-width: 225px;
  text-transform: uppercase;
}

.coaching__course__paragraph {
  font-family: Montserrat-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #FFFFFF;
  max-width: 225px;
}

.div3below {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
}


.hoverbelowDiv {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 24px;
  padding-right: 24px;
  background: rgba(15, 111, 255, 1);
  color: rgba(255, 255, 255, 1);
  width: 100%;
  border-radius: 20px;
}

.hoverbelowDiv__1 {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 24px;
  padding-right: 24px;
  background: rgba(15, 111, 255, 1);
  color: rgba(255, 255, 255, 1);
  width: 100%;
  border-radius: 20px;
  height: 266px;
}

.arrow {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  width: 60px;
  height: 60px;
}


.hover-content {

  height: 100%;
  top: 62%;
  left: 50%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  gap: 40px;
  opacity: 0;
  /* Initially hidden */
  visibility: hidden;
  /* Prevent interaction */
  transition: opacity 0.5s ease 0.5s, visibility 0.5s ease 0.5s;

}

.hover_list__items-wrape {
  margin-bottom: 0px !important;
}

.hover_list__items-wrape__1 {
  display: flex !important;
  flex-direction: column !important;
  gap: 20px !important;
  margin-top: 10px !important;
}

.hover_list__item__1 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.94px;
  text-align: left;
  color: #FFF;

}

.image-card:hover .default-image {
  display: none;

}

.image-card:hover .hover-image {
  display: block;
  opacity: 1;
  /* Fade-in effect */
  visibility: visible;
  /* Make visible */
  transition-delay: 0.5s;
}

.image-card:hover .hover-content {
  display: flex;
  opacity: 1;
  /* Fade-in effect */
  visibility: visible;
  /* Make visible */
  transition-delay: 0.5s;
  /* Add delay to content appearance */

}

.hovrBelowpara1 {
  font-family: "Exo 2";
  font-size: 18px;
  line-height: 20px;
  font-weight: 700 !important;
  margin-bottom: 0;
  background: linear-gradient(169.97deg, #ffffff 8.65%, #cdcdcd 93.64%);
  -webkit-background-clip: text;
  /* For Safari */
  background-clip: text;
  /* For other browsers */
  color: transparent;
  text-transform: uppercase;
  padding-bottom: 10px;
}

.hoverBelowpara2 {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
  /* padding-bottom: 30px; */
}

.hover_list__item {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
}

/* button {
  background: white;
  color: #0066ff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
 
} */

/* button:hover {
  background: #0066ff;
  color: white;
} */

.arrowDiv {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.arrowDiv2 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}


.DownLoadButton {
  margin-top: 10px !important;
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 12px;
  height: 54px;
  width: 153px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: none !important;
  font-family: "Exo 2" !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: rgba(255, 255, 255, 1) !important;
  transition: background 0.3s ease;
}

.DownLoadButton:hover {
  background: rgba(10, 80, 200, 1) !important;
  /* Darker shade of blue */
}

.div4 {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* background-image: url("/public/Images/LandingPage/Ellipse\ 1829.png");
background-repeat: no-repeat;
background-position: center; */
  padding-top: 161px;
  padding-bottom: 170px;
  gap: 100px;

}

.div4Top {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
}

.div4topPara {
  font-family: Montserrat;
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  text-align: center;
  color: rgba(255, 255, 255, 1) !important;
  margin-bottom: 0;
  /* padding-top: 20px; */
}

.div4below {
  width: 100%;
  gap: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 100px;
  padding-right: 55px;
  position: relative;
}

.blurness-center {

  position: absolute;
  top: 0%;
  left: 13%;
  width: 67%;
  height: 54%;
  background-color: #022EEB !important;
  filter: blur(234px);
  border-radius: 50%;
  /* Apply blur directly */
  z-index: -1;
}

.div4Form {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
  ;
  display: flex;
  flex-direction: column;
  width: 520px;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 50px;
  padding-right: 50px;
  gap: 50px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  /* Apply rounded corners */
  backdrop-filter: blur(150px) !important;
  /* Apply the blur effect */
  -webkit-backdrop-filter: blur(150px) !important;
}

.div4right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}

.InputsDiv4 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.menu__item1 {
  font-family: Exo2-SemiBold !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.005em !important;
  text-align: left !important;
  color: #FFFFFF !important;
  margin-bottom: 8px !important;
}

.dropdown-menu-lists-skainet-academy {
  margin-top: 10px !important;
  background-color: #0D1226 !important;
  width: 100% !important;
  border-radius: 16px !important;
  border: 1px solid #FFFFFF !important;
}

.NexTFormButton {
  border-radius: 16px;
  height: 64px;
  width: 100%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: rgba(15, 111, 255, 1) !important;
  border: none !important;
  font-family: "Exo 2" !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: rgba(255, 255, 255, 1) !important;
  transition: background 0.3s ease;
}

.NexTFormButton:hover {
  background: rgba(10, 80, 200, 1) !important;
  /* Darker shade of blue */
}

.Input1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.Dropdown-menu-main-text {
  padding: 16px !important;
  border-radius: 16px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  border: 1px solid #FFFFFF !important;
  width: 100% !important;
  background-color: transparent !important;
}

.select__country__text {
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  color: rgba(215, 216, 217, 1) !important;
  text-align: left !important;
  margin-bottom: 8px !important;
}

.skainet__dropdown__describtion {
  font-family: Exo2-SemiBold;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #FFFFFF;
  margin-bottom: 8px !important;
}

.dropdown-skainet-academy {
  width: 100% !important;
}

.Input2 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 48.5%;
}

.InputFormpara {
  font-family: "Exo 2";
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0;
  /* letter-spacing: ; */
}

.NameInput {
  border: 1px solid rgba(255, 255, 255, 1);
  padding: 16px;
  background-color: transparent;
  border-radius: 16px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: rgba(215, 216, 217, 1);
}

.NameInput::placeholder {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: rgba(215, 216, 217, 1);
}

.NameInput:focus {
  outline: none;
}

.input-wrapper {
  border: 1px solid rgba(255, 255, 255, 1);
  padding: 16px;
  background-color: transparent;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CardInput {
  border: none;
  background-color: transparent;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: rgba(215, 216, 217, 1);

}

.CardInput::placeholder {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: rgba(215, 216, 217, 1);
}

.CardInput:focus {
  outline: none;
}

.formRow {
  display: flex;
  gap: 10px;
}

.custom-dropdown {
  background: url('../../assets/dropdownarrow.svg') no-repeat right 10px center;
  /* Add your custom icon here */
  background-size: 16px;
  appearance: none;
  padding-right: 16px !important;
  border: 1px solid rgba(255, 255, 255, 1);
  padding: 16px;
  background-color: transparent;
  border-radius: 16px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: rgba(215, 216, 217, 1);
}

.custom-dropdown:focus {
  outline: none;
  background-color: transparent !important;
}

.menu__item1:focus {
  background-color: transparent !important;
}

.menu__item1:hover {
  background-color: #0F6FFF !important;
}

.dropdown-menu-lists-skainet-academy {
  padding: 25px 0px 17px !important;
}

.custom-dropdown option {
  border: none !important;

  border-radius: 16px !important;
  background-color: #030e34 !important;
  /* Transparent options */
  font-family: Montserrat;
  /* Custom font */
  font-size: 16px;
  font-weight: 500;
  color: rgba(215, 216, 217, 1);
  /* Font color */
}

.custom-dropdown option:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
  /* Slight hover effect for options */
  color: rgba(255, 255, 255, 1);
  /* Change text color on hover */
  width: 100% !important;
}

.finalDiv {
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 150px;
  padding-top: 277px !important;
  display: flex;
  flex-direction: column;

  position: relative;

}

.wrappingDiv {
  /* background: #0F6FFF1A; */
  background-color: #0F6FFF1A;
  position: relative;
  width: 100%;
  overflow: hidden;
}

/* .BlurnessFinal{
  position: absolute;
  top: 61%;
  right: -23%;
  width: 75%;
  height: 30%;
    background-color: #022EEB !important;
    filter: blur(350px);
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  z-index: -1;
} */
.finalheading {
  width: 100%;
  display: flex;
  justify-content: center;
}

.finalbottom {
  display: flex;
  gap: 29px;
  margin-top: 100px;
}

.FINALBOTTOMLEFT {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 40px; */
}

.FinalBox1 {
  padding-top: 20px !important;
  border-left: 4px solid rgba(15, 111, 255, 0.2);
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  transition: border-color 0.3s ease;
  padding-bottom: 25px;
}

.FinalBox {
  padding-top: 40px;
  border-left: 4px solid rgba(15, 111, 255, 0.2);
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  transition: border-color 0.3s ease;
  padding-bottom: 25px;
}

.FinalBox1:hover {
  border-left: 4px solid rgba(15, 111, 255, 1);
  /* New border color on hover */
}

.FinalBox:hover {
  border-left: 4px solid rgba(15, 111, 255, 1);
  /* New border color on hover */
}

.finalBBoxUpper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.InstaFinal {
  font-family: "Exo 2";
  font-size: 46px;
  font-weight: 700;
  line-height: 42.32px;
  background: linear-gradient(169.97deg, #ffffff 8.65%, #cdcdcd 93.64%);
  -webkit-background-clip: text;
  /* For Safari */
  background-clip: text;
  /* For other browsers */
  color: transparent;
  /* Make text transparent to show gradient */
  margin-bottom: 0;
  text-transform: uppercase;

}

.InstaFinalPara {
  margin: 0px !important;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
}

.pricing__plan_sb_heading {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0;
  text-align: center;
  margin-top: 20px !important;
}

.pricing__plan-card-first {
  display: flex;
  flex-direction: column;
  background: #FFFFFF0D;
  border: 1px solid #FFFFFF33;
  border-radius: 40px;
  padding: 55px 48px 50px 47px;
}

.pricing__plan-card-first3 {
  display: flex;
  flex-direction: column;
  background: #FFFFFF0D;
  border: 1px solid #FFFFFF33;
  border-radius: 40px;
  padding: 55px 48px 50px 47px;
}

.Monthly__text__wrape {
  max-width: 300px;
}

.pricing__plan-cards-wraper-button {
  display: flex;
  flex-direction: row;
}

.pricing__plan-card-first {
  display: flex;

}

.pricing__plan-card-basic-text {
  background-color: #F1F1F1;
  padding: 6px 15px;
  border-radius: 6px;
  margin-bottom: 15px !important;
}

.Monthly__text {
  font-family: Montserrat-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  color: #FFFFFF;
}

.lambi-line-below-text {
  font-family: Exo2-Bold;
  font-size: 70px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #0F6FFF;
  margin-bottom: 8px !important;
}

.lambi-line-below-text2 {
  font-family: Exo2-Bold;
  font-size: 70px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #FFFFFF;
  margin-bottom: 8px !important;
}

.star__trading__btn {
  height: 64px !important;
  outline: none;
  border: none;
  margin-top: 50px !important;
  padding: 25px 30px;
  background-color: #0F6FFF;
  border-radius: 16px;
  font-family: Exo2-Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  align-items: center;
}

.star__trading__btn-change {
  height: 64px !important;
  outline: none;
  border: none;
  margin-top: 50px !important;
  padding: 25px 30px;
  background-color: #FFFFFF;
  border-radius: 16px;
  font-family: Exo2-Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #0F6FFF;
  display: flex;
  align-items: center;
}

.lambi-line-below-text-wrape {
  max-width: 215px;
}

.pricing__plan-cards-wraper {
  margin-top: 80px !important;
}

.per__member-text {
  font-family: Montserrat-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: #FFFFFF;
}

.lambi-line__wrape {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.pricing__plan-card-basic-text__ {
  font-family: Exo2-SemiBold;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #00061B;

}

.pricing__plan-card-basic-text-btn-wraper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.pricing__plan-cards-wraper {
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: center;
  gap: 28px;
}

.pricing__plan-card-second {
  display: flex;
  flex-direction: column;
  background: #0F6FFFB2;
  border: 1px solid #FFFFFF33;
  border-radius: 40px;
  padding: 45px 47px;
}

.pricing__plan-card-first-column1 {
  padding-top: 75px !important;
}

.PurchaseButton {
  border-radius: 16px;
  height: 42px;
  width: 158px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: rgba(15, 111, 255, 1) !important;
  border: none !important;
  font-family: "Exo 2" !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 21.6px !important;
  color: rgba(255, 255, 255, 1) !important;
  transition: background 0.3s ease;
}

.PurchaseButton:hover {
  background: rgba(10, 80, 200, 1) !important;
  /* Darker shade of blue */
}

.finalBottomright {
  width: 100%;
}

.video-player-containerss {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  /* border-radius: 8px; */
  /* overflow: hidden; */
  width: 100%;
  /* margin: auto; */
  position: relative;
}

/*  */

.video-js {
  width: 100% !important;
  height: 500px !important;
  background-color: transparent !important;
  border-radius: 67.9px;
}

.vjs-control {
  width: 90%;
  background-color: gray !important;
  color: white !important;
  z-index: 5;
  position: absolute !important;
  bottom: -45;

}

.vjs-play-control {
  color: white;
}

.vjs-play-control:hover {
  color: #ff0000;
}

.video-element {
  border-radius: 68.9px;
  width: 100%;
  height: auto;
  background: transparent;
}

.pricing__plan-cards-bottom-divtop {
  background: #0F6FFF;
  border-radius: 16px;
  padding: 26px 35px;
}

.testimonial-card-sbtext {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #FFFFFF;
}

.testimonials-card1-content-wrape {

  margin-top: 30px !important;
  display: flex;
  flex-direction: column;
  gap: 18px
}

.testimonials-card1 {
  width: 400px;
  padding: 50px 40px;
  border-radius: 30px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
  border: 1px solid #FFFFFF33;
  position: relative;
}

.testimonials-card-wraper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 80px !important;
}

.wrappingDiv {
  padding: 120px 100px;
}

.Sarah-text {
  font-family: Exo2-ExtraBold;
  font-size: 28px;
  font-weight: 800;
  line-height: 33.6px;
  text-align: left;
  color: #FFFFFF;

}

.pricing__plan-cards-bottom-divtop-text {
  font-family: Exo2-Regular;
  font-size: 22px;
  font-weight: 400;
  line-height: 26.4px;
  text-align: left;
  color: #FFFFFF;
}

.pricing__plan-cards-bottom {
  margin-top: 30px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.payment-text-method {
  font-family: Exo2-Medium;
  font-size: 25px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
  color: #FFFFFF;
}

.payment-method-imgs-wrape {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  justify-content: center;
  margin-top: 10px !important;
}

.payment-text-method-below-text {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: #FFFFFF;
}

.payment-text-method-below-text-wrape {
  max-width: 492px;
}

.controlss {
  position: absolute;
  /* bottom: -40px;  */
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  display: flex;
  border-radius: 137.9px;
  align-items: center;
  justify-content: space-between;
  padding: 13.79px 17.24px 17.24px 17.24px;
  background-color: rgba(51, 58, 64, 1);
  color: white;
  gap: 20px;
}

.lambi-line__wrape {
  height: 1px;
  background-color: #FFFFFF33;
}

.playButton {
  display: flex;
  align-items: center;
  background: none !important;
  border: none;
  padding: "10px 20px 20px 20px";
}

.play-pause-btn,
.settings-btn,
.fullscreen-btn {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
}

.globalPara-wrape {
  max-width: 800px !important;
  display: flex;
  flex-direction: column;
}

.progress-bar {
  margin: 0;
  flex-grow: 1;
  height: 4px;
  background: #555;
  appearance: none;
}

.progress-bar::-webkit-slider-thumb {
  appearance: none;
  width: 100%;
  height: 10px;
  background: #EF4444;
  cursor: pointer;
}

.InstaGramMastryImage {
  height: 80px;
  width: 80px;
}

.progres-again-try {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.time-display {
  font-size: 14px;
  white-space: nowrap;
}

.video-play-and-stop-the-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 94%;

}

.button-and-time {
  display: flex;
  gap: 0px;
  align-items: center;
}

.setting {
  color: white;
  font-size: 24px !important;
}

.Other-video-icons {
  display: flex;
  gap: 10px;
  align-items: center;
}

.volume-bar {
  -webkit-appearance: none;
  appearance: none;
  width: 40px;
  height: 6px;
  background-color: gray;
  /* background: linear-gradient(to right, white var(--volume-fill, 50%), transparent var(--volume-fill, 50%)); */
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  position: relative;
  transition: background 0.3s ease;
}

.benefits__div__wraperr {
  /* padding-bottom: 120px !important; */
}

.benefits__div__wraperr__bg_blur {
  position: absolute;
  bottom: 10%;
  left: 13%;
  width: 788px;
  height: 720px;
  background-color: #022EEB !important;
  filter: blur(285px);
  border-radius: 50%;
  z-index: -1;
}

.new-mobile-div {
  display: none !important;
}

.benefits__div__wraperr__bg_blur2 {
  position: absolute;
  bottom: 10%;
  right: -30%;
  width: 788px;
  height: 600px;
  background-color: #022EEB !important;
  filter: blur(285px);
  border-radius: 50%;
  z-index: -1;
}

.pricing__plan__div-wraper {
  position: relative;
}

.globalPara-wrape {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px !important;
}

.div2Belowleft__heading {
  font-family: "Exo2-ExtraBold";
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
  text-align: left;
  background: linear-gradient(169.97deg, #FFFFFF 8.65%, #CDCDCD 93.64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 30px !important;
}

.volume-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.volume-bar::-moz-range-progress {
  background: white;
  height: 6px;
  border-radius: 10px;
}

.volume-bar::-moz-range-track {
  background: transparent;
  height: 6px;
  border-radius: 10px;
}

.div4right img {
  width: 100%;
}


.TimePara {
  font-family: "poppins" !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
  margin: 0px;
}


.Image-for-small1 {
  background-image: url('/src/assets/greendivbox.png');
  background-size: 100% 100%;
  width: 100%;
  height: 380px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}





.Image-for-small2 {
  background-image: url('/src/assets/brownDivBox.png');
  background-size: 100% 100%;
  width: 100%;
  height: 380px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Image-for-small3 {
  background-image: url('/src/assets/image_3.png');
  background-size: 100% 100%;
  width: 100%;
  height: 380px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Image-for-small4 {
  background-image: url('/src/assets/4thDivBox.png');
  background-size: 100% 100%;
  width: 100%;
  height: 380px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.blue-div-design {
  background-color: #0F6FFF;
  border-radius: 20px;
  padding: 20px;
}

.blue-div-design__1 {
  background-color: #0F6FFF;
  border-radius: 20px;
  padding: 20px;
  height: 150px;

}

.hover_list__items-wrape__2 {
  display: flex;
  flex-direction: column;
  gap: 10px
}

.skainetcontent {
  font-family: "Exo 2";
  font-size: 16px;
  line-height: 20px;
  font-weight: 700 !important;
  margin-bottom: 0;
  background: linear-gradient(169.97deg, #ffffff 8.65%, #cdcdcd 93.64%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-transform: uppercase;
  padding-bottom: 10px;
  margin: 0px !important;
}

.skainetcontent2 {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0;
  color: #FFFFFF;
  padding-bottom: 10px;
  margin: 0px !important;
}











@media(min-width:1600px) {

  .div4right img {
    width: 70% !important;
  }
}

@media(min-width:1500px) {
  .div2Box {
    width: 700px;
  }

  .globaldivright {
    width: 60%;
  }

  .div4right img {
    width: 80%;
  }
}

@media(max-width:1360px) {
  .pricing__plan-card-first {
    display: flex;
    flex-direction: column;
    background: #FFFFFF0D;
    border: 1px solid #FFFFFF33;
    border-radius: 38px;
    padding: 45px 37px 45px 37px;
  }

  .lambi-line__wrape {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .lambi-line-below-text {
    font-size: 62px;
    line-height: 52px;
  }

  .star__trading__btn {
    margin-top: 40px !important;
    padding: 20px 25px;
    border-radius: 16px;
    font-size: 18px;
    line-height: 22px;
  }

  .lambi-line-below-text2 {
    font-size: 62px;
    line-height: 52px;
  }

  .star__trading__btn-change {
    border: none;
    margin-top: 40px !important;
    padding: 20px 25px;
    font-size: 18px;
    line-height: 22px;
  }
}

@media (max-width:1346px) {
  .div4Form {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }

  .div4below {
    padding: 0;
    justify-content: center;
    flex-direction: column;
  }

  .div4right {
    width: 100%;
    justify-content: center;
  }

  .div4right img {
    width: 70%;
  }

}

@media(max-width:1320px) {
  .pricing__plan-cards-wraper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: top;
    justify-content: center;
    gap: 20px;
  }

  .pricing__plan-card-first-column1 {
    padding-top: 75px !important;
  }

  .testimonials-card-wraper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 80px !important;
  }

  .testimonials-card1 {
    max-width: 500px;
  }

}

@media (max-width:1265px) {
  .div2below {
    flex-direction: column;
  }

  .div2BelowRight {
    display: flex;
    justify-content: center;
  }

}

@media (max-width:1230px) {
  .finalbottom {
    flex-direction: column;
  }

  .FINALBOTTOMLEFT {
    width: 70%;
  }

  .globalHeading {
    font-size: 70px;
    line-height: 65px;
  }

  .globaldiv1Top {
    gap: 10px;
  }

  .GlobalDiv1 {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 80px;
  }

  .finalDiv {
    padding: 100px !important;
  }

  .whitelinesdiv {
    margin-top: 60px;
  }

  .TopLinePara {
    font-size: 26px;
  }

  .courses___cards__main__container {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
}

@media (max-width:1147px) {
  .div3Topleft {
    width: 80% !important;
  }

  .div3Top {
    gap: 100px;
  }

  .breakLine3 {
    display: none;
  }

  .div2 {
    padding: 80px;
    gap: 40px;
  }

  .div2Belowleft {
    /* flex-direction: row; */
  }

  .div2Box {
    padding: 40px;
  }

  .new {
    display: none;
  }

  .div2topPara {
    text-align: center;
    padding: 0px !important;
  }
}

@media (max-width:1082px) {
  .breakline1 {
    display: none;
  }

  .globalHeading {
    font-size: 60px;
    line-height: 55px;
  }

  .globalPara {
    font-size: 14px;
    line-height: 20px;
  }

}

@media (max-width:1034px) {
  .div3Top {
    gap: 0px;
  }

  .div3 {
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media(max-width:1024px) {
  .finalBottomright img {
    width: 100%;
  }
}

@media (max-width:1019px) {
  .pricing__plan-card-first-column1 {
    padding-top: 0px !important;
  }

  .globalHeading {
    font-size: 60px;
    line-height: 66px;
  }

  .Div2Heading {
    font-size: 60px;
    line-height: 66px;
  }

  .globaldivright {
    width: 100%;
  }

  .playButton {
    padding: 10px;
  }

}

@media(max-width:955px) {
  .FINALBOTTOMLEFT {
    width: 90%;
  }

  .globalHeading {
    font-size: 50px;
    line-height: 55px;
  }

  .GlobalDiv1 {
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 70px;
  }

  .whitelinesdiv {
    margin-top: 50px
  }

  .bottomLine,
  .TopLine {
    height: 65px;
  }

  .TopLinePara {
    font-size: 24px;
  }

  .scrolling-container {
    gap: 20px;
  }
}

@media (max-width:902px) {
  .div3Top {
    flex-direction: column;
    gap: 25px;
  }

  .div3Topleft {
    width: 100% !important;
    align-items: center;
  }

  .contentpara {
    text-align: center;
  }

  .controlss {
    padding: 10px
  }

  .setting {
    font-size: 18px !important;
  }

  .playButton {
    padding: 5px 5px 5px 5px !important;
  }

  .volume-bar {
    width: 35px;
    height: 4px;

  }

  .volume-bar::-webkit-slider-thumb {
    width: 8px;
    height: 8px;
  }
}

@media (max-width:800px) {
  .div2BelowRight {
    width: 100%;
  }

  .div2BelowRight img {
    width: 100%;
  }
}

@media (max-width:811px) {
  .globalHeading {
    font-size: 46px;
    line-height: 50px;
  }

  .Div2Heading {
    font-size: 46px;
    line-height: 50px;
  }

  .globalPara {
    font-size: 12px;
    line-height: 20px;
  }

  .GlobalDiv1 {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 60px;
  }

  .div2topPara {
    font-size: 18px;
    line-height: 22px;
  }

  .div2topPara {
    font-size: 14px;
    line-height: 18px
  }

  .div2Box {
    padding: 20px;
    gap: 10px;
  }

  .finalDiv {
    padding: 70px !important;
    gap: 0px !important;
  }

  .finalbottom {
    margin-top: 50px;
  }

  .div2 {
    padding: 50px;
  }

  .contentpara {
    font-size: 18px;
    line-height: 22px;
  }

  .whitelinesdiv {
    height: 150px;
  }

  .div4topPara {
    font-size: 18px;
    line-height: 22px;
  }

  .InstaFinal {
    font-size: 38px;
    line-height: 42px;
  }

  .InstaFinalPara {
    font-size: 18px;
    line-height: 24px;
  }

  .whitelinesdiv {
    margin-top: 40px !important;
  }

  .globaldiv1Top {
    gap: 18px !important;
  }

  .bottomLine,
  .TopLine {
    height: 55px;
  }

  .TopLinePara {
    font-size: 20px;
  }

  .scrolling-container {
    gap: 14px;
  }

  .wrappingDiv {
    padding: 90px 20px !important;
  }
}

@media (max-width:768px) {
  .GlobalDiv1 {
    padding-top: 50px !important;
    gap: 22px;
  }

  .globaldiv1Top {
    gap: 22px;
  }

  .div2 {
    padding-top: 50px;
    gap: 40px;
    padding-bottom: 50px;
  }

  .div3 {
    padding-top: 50px;
    gap: 30px;
    padding-bottom: 50px;
  }

  .div4 {
    padding-top: 50px;
    gap: 60px;
    padding-bottom: 70px;
  }

  .div4Form {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .div3 {
    padding-left: 60px;
    padding-right: 60px;
}
  .div4right {
    justify-content: center;
  }

  .div4right img {
    width: 70% !important;
    margin-right: 0;
  }

  .finalDiv {
    gap: 50px;
    padding-bottom: 70px;
  }

  .FinalBox {
    padding-top: 20px;
    padding-bottom: 15px;
  }
}

@media (max-width:760px) {

  
  .video-element {
    border-radius: 42.5px;
  }

  .controlss {
    border-radius: 80.5px;
  }

  .pricing__plan-cards-bottom-divtop-text {
    font-family: Exo2-Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    color: #FFFFFF;
  }

  .globalHeading {
    font-size: 36px;
    line-height: 40px;
  }

  .globaldiv1Top {
    gap: 14px !important;
  }

  .bottomLine,
  .TopLine {
    height: 45px;
  }

  .TopLinePara {
    font-size: 18px;
  }

  .wrappingDiv {
    padding: 80px 20px !important;
  }
}
@media(max-width:700px)
{
  .div2BelowRight img 
  {
    object-fit: contain;
  }
}
@media (max-width:666px) {
  .globalHeading {
    font-size: 32px;
    line-height: 38px;
  }

  .Div2Heading {
    text-align: center;
    font-size: 36px;
    line-height: 40px;
  }

  .courses___cards__main__container {
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.testimonials-card1 {
  width: 100%;
}

  .div2topPara {
    font-size: 16px;
    line-height: 20px;
  }

  .contentpara {
    font-size: 16px;
    line-height: 20px;
  }

  .TopLinePara {
    font-size: 24px;
    line-height: 36px;
  }

  .TopLine {
    height: 55px;
  }

  .bottomLine {
    height: 55px;
  }

  .div4topPara {
    font-size: 18px;
    line-height: 22px;
  }

  .InstaFinal {
    font-size: 28px;
    line-height: 34px;
  }

  .pricing__plan-cards-bottom-divtop-text {
    font-family: Exo2-Regular;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 22.4px !important;
    text-align: left;
    color: #FFFFFF;
  }

  .wrappingDiv {
    padding: 70px 24px !important;
  }
  .div3 {
    padding-left: 24px;
    padding-right: 24px;
}

  .InstaFinalPara {
    font-size: 16px;
    line-height: 22px;
  }

  .LearnMoreButton {
    font-size: 16px !important;
    height: 46px !important;
  }

  .startNowButton {
    font-size: 16px !important;
    height: 46px !important;
    white-space: nowrap !important;
  }

  /* .whitelinesdiv{
            padding-top: 50px !important;
          } */
  .Viewdiv3Button {
    font-size: 16px !important;
    height: 46px !important;

  }

  .div1leftButtons {
    margin-top: 20px !important;
    width: 100% !important;
    /* flex-direction: column !important; */

  }
  /* .div3 {
    padding-left: 40px;
    padding-right: 40px;
} */
  .NexTFormButton {
    font-size: 16px !important;
    height: 46px !important;
  }

  .Purchase {
    font-size: 16px !important;
    height: 46px !important;
  }

  .FINALBOTTOMLEFT {
    width: 100%;
  }

  .DownLoadButton {
    font-size: 16px !important;
    height: 46px !important;
  }

  .arrow {
    width: 45px;
    height: 45px;
  }
}

@media (max-width:623px) {
  .div4Form {
    margin-right: 24px !important;
    margin-left: 24px !important;
    width: 90%;
  }

  .div4right {
    width: 100%;
  }

  .div4right img {
    width: 100%;
  }

  .pricing__plan-card-first-column1 {
    padding-top: 0px !important;
  }

  .testimonials-card1-content-wrape {
    margin-top: 0px !important;
    padding-top: 8px !important;
  }

  /* .testimonials-card1 {
    max-width: 300px !important;
  } */

  .testimonials-card1 {
    padding: 25px !important;
  }

  .Sarah-text {
    margin-bottom: 5px !important;
  }

  .pricing__plan_sb_heading {
    font-size: 16px;
    line-height: 24px;
  }

  .breakline4 {
    display: none;
  }

  .controlss {
    width: 96%;
  }

  .imageinstax {
    width: 400px;
    height: 400px;

  }
}

@media (max-width:600px) {
  .pricing__plan-card-first {
    width: 350px !important;
  }

  .imageinstax {
    /* height: 500px; */
    margin-left: 0px !important;
  }

  .pricing__plan-card-second {
    width: 350px !important;
  }

  .helowediv {
    padding: 8px;
  }

  .helowe {
    font-size: 14px;
    line-height: 18px;

  }

  .whitelinesdiv {
    height: 100px;
  }

  .globaldiv1Top {
    gap:
      10px;
  }

  .star__trading__btn {
    padding: 15px 20px !important;
    margin-top: 20px !important;
  }

  .TopLinePara {
    font-size: 20px;
    line-height: 30px;
  }

  .coaching___program__card__courses{
    padding: 60px 35px !important;
  }


  .TopLine {
    height: 55px;
  }

  .bottomLine {
    height: 55px;
  }

  .Monthly__text {
    font-size: 12px !important;
    line-height: 16.5px !important;
  }

  .pricing__plan-card-first3 {
    width: 350px !important;
  }

  .div2 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .imageinstax {
    /* width: 350px;
    height: 350px; */

  }
}

@media(max-width:550px) {
  .globalHeading {
    font-size: 45px;
    line-height: 50px;
  }

  .globalPara {
    font-size: 14px;
    line-height: 18px;

  }

  .GlobalDiv1 {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
  }

  .bottomLine,
  .TopLine {
    height: 40px;
  }

  .TopLinePara {
    font-size: 18px;
    line-height: 30px;
  }

  .whitelinesdiv {
    margin-top: 30px !important;
  }
}

@media(max-width:522px) {
  .GlobalDiv1 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .imageinstax {
    /* width: 300px;
    height: 300px; */

  }

  .div2 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .div3 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .finalDiv {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .div3 {
    padding-left: 24px;
    padding-right: 24px;
}
}

@media (max-width:500px) {
  .div2Box {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .image-card {

    width: auto;
    height: auto;
  }

  .pricing__plan__div-wraper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pricing__plan-card-first-column1 {
    padding-top: 0px !important;
  }







  .new-mobile-div {
    display: flex !important;
    flex-direction: column;
    gap: 20px;
  }

  .div3below {
    display: none;
  }

  .payment-method-imgs-wrape {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;
    justify-content: center;
    margin-top: 10px !important;
  }

  .wrappingDiv {
    padding: 40px 20px !important;
  }

  .hoverbelowDiv {
    padding: 10px;
  }

  .pricing__plan-cards-bottom-divtop {
    background: #0F6FFF;
    border-radius: 16px;
    padding: 15px 25px !important;
  }

  .hover_list__item__1 {
    font-size: 13px !important;
    line-height: 16.95px !important;
  }
}

@media(max-width:480px) {
  .pricing__plan-cards-bottom-divtop-text {
    font-family: Exo2-Regular;
    font-size: 13px !important;
    font-weight: 400;
    line-height: 26.4px !important;
    text-align: left;
    color: #FFFFFF;
  }

}

@media (max-width:425px) {
  .LearnMoreButton {
    width: 100%;
  }

  .TopLinePara {
    font-size: 20px;
    line-height: 36px;
  }

  .finalDiv {
    padding: 35px !important;

  }

  .finalbottom {
    margin-top: 30px;
  }

  .FinalBox1 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .InstaFinal {
    font-size: 24px;
    line-height: 30px;
  }

  .InstaFinalPara {
    font-size: 12px;
    line-height: 18px;
  }

  .ParaFooter1 {
    font-size: 14px;
    line-height: 18px;
  }

  .Footer1-content {
    gap: 20px !important;
  }

  /* .Footer2 {
    gap: 15px !important;
  } */

  .imageinstax {
    /* width: 250px;
    height: 280px; */

  }

  .FooterParaLogo {
    font-size: 16px !important;
    line-height: 18px !important;
  }


  .finalBBoxUpper {
    gap: 10px;
  }

  .TopLinePara {
    font-size: 18px;
    line-height: 26px;
  }

  .scrolling-container {
    gap: 12px;
  }

  .hover_list__item {
    font-size: 10px;
    line-height: 14px;
  }

  .hoverBelowpara2 {
    font-size: 9px;
    line-height: 14px;
  }

  .hovrBelowpara1 {
    font-size: 17px;
    line-height: 18px;
    padding-bottom: 10px;
  }



  .div1leftButtons {
    width: 150px !important;
  }

  .finalDiv {
    padding-top: 30px !important;
  }

  .pricing__plan-cards-wraper {
    margin-top: 35px !important;
  }

  .benefits__div__wraperr {
    padding-bottom: 40px !important;
  }

  .testimonials-container {

    padding: 35px 4px !important;
  }

  .testimonials-card-wraper {
    margin-top: 35px !important;
  }

  .Footer1-content {
    padding-bottom: 60.5px;

  }

  .lambi-line-below-text {
    font-size: 60px !important;
    line-height: 45px !important;
  }

  .pricing__plan-card-first {
    padding: 35px 30px 40px 30px !important;
  }

  .pricing__plan-cards-bottom-divtop {
    background: #0F6FFF;
    border-radius: 16px;
    padding: 12px 15px;
  }

  .lambi-line__wrape {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .star__trading__btn {
    margin-top: 30px !important;
    padding: 16px 22px;
    border-radius: 16px;
    font-size: 17px;
    line-height: 21px;
  }

  .pricing__plan-card-first-column1 {
    padding-top: 0px !important;
  }

  .pricing__plan-card-second {
    padding: 35px 40px !important;
  }

  .lambi-line-below-text2 {
    font-size: 50px;
    line-height: 50px;
  }

  .Monthly__text {
    font-family: Montserrat-Medium;
    font-size: 12px;
    font-weight: 500;
    line-height: 16.5px;
    text-align: left;
    color: #FFFFFF;
  }

  .startNowButton {
    width: 100%;
  }
}



@media (max-width:422px) {
  .div2Box {
    flex-direction: column;
  }

  .InstaGramMastryImage {
    height: 80px;
    width: 80px;
  }


  .pricing__plan-cards-bottom-divtop {
    background: #0F6FFF;
    border-radius: 16px;
    padding: 10px 20px !important;
  }

  .div2 {
    padding-top: 30px;
  }

  .pricing__plan-card-first {
    max-width: 280px !important;
  }

  .LearnMoreButton {
    font-size: 14px !important;
  }

  .startNowButton {
    font-size: 14px !important;
  }
}

@media (max-width:400px) {
  .div4Form {
    padding-left: 25px;
    padding-right: 25px;
  }

  .pricing__plan-card-first {
    display: flex;
    flex-direction: column;
    background: #FFFFFF0D;
    border: 1px solid #FFFFFF33;
    border-radius: 36px;
    padding: 18px 25px 45px 25px;
  }

  .pricing__plan-card-first3 {
    width: 280px !important;
    padding: 35px 40px !important;
  }

  .helowe {
    font-family: "Exo 2" !important;
    color: rgba(255, 255, 255, 1);
    font-size: 14px !important;
    line-height: 18.6px !important;
    font-weight: 400;
    margin-bottom: 0;
  }

  .GlobalDiv1 {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px !important;
  }

  .pricing__plan-card-second {
    display: flex;
    flex-direction: column;
    background: #0F6FFFB2;
    border: 1px solid #FFFFFF33;
    border-radius: 36px;
    padding: 30px 25px;
  }


  .pricing__plan-cards-bottom-divtop {
    background: #0F6FFF;
    border-radius: 16px;
    padding: 12px 15px;
  }

  .lambi-line-below-text {
    font-size: 56px;
    line-height: 40px;
  }

  .star__trading__btn {
    margin-top: 30px !important;
    padding: 16px 20px;
    border-radius: 16px;
    font-size: 16px;
    line-height: 18px;
  }

  .pricing__plan-cards-bottom-divtop-text {
    font-size: 11px !important;

  }

  .pricing__plan-card-second {
    max-width: 280px !important;
  }

  .testimonials-card1 {
    padding: 40px 20px;
  }

  .star__trading__btn-change {
    margin-top: 30px !important;
    padding: 16px 20px;
    border-radius: 16px;
    font-size: 16px;
    line-height: 18px;
  }

  .pricing__plan-cards-bottom-divtop-text {
    font-family: Exo2-Regular;
    font-size: 10px !important;
    font-weight: 400;
    line-height: 26.4px !important;
    text-align: left;
    color: #FFFFFF;
  }

  .setting {
    font-size: 12px !important;
  }

  .hover_list__item__1 {
    font-size: 12px !important;
    line-height: 14.95px !important;
  }
}
.div2below
{
  gap:0px
}
.div2
{
  padding-bottom: 0px !important;
}
@media (max-width:386px) {


  .globaldiv1Top {
    gap: 5px;
  }
}

@media (max-width:375px) {
  .div1leftButtons {
    flex-direction: column;
    gap: 20px;
  }

  .pricing__plan-card-second {
    display: flex;
    flex-direction: column;
    background: #0F6FFFB2;
    border: 1px solid #FFFFFF33;
    border-radius: 40px;
    padding: 40px 30px;
  }

  .div2Box {
    padding: 15px;
  }
}

@media (max-width:355px) {
  .div4Form {
    padding-left: 20px;
    padding-right: 20px;
  }

  .Image-for-small1,
  .Image-for-small2,
  .Image-for-small3,
  .Image-for-small4 {
    height: 320px;
  }

  .bottomLine,
  .TopLine {
    height: 30px;
  }

  .TopLinePara {
    font-size: 14px;
    line-height: 24px;
  }

  .imageinstax {
    /* width: 200px; */
    /* height: 250px; */
  }

  .skainetcontent {
    font-size: 13px;
    padding-bottom: 5px;
  }

  .hover_list__item {
    font-size: 8px;
    line-height: 14px;
  }

  .skainetcontent2 {
    font-size: 10px;
  }

  .NameInput {
    font-size: 12px;
  }

  .NameInput::placeholder {
    font-size: 12px;
  }

  .CardInput {
    font-size: 12px;
  }

  .TopLinePara {
    font-size: 14px;
    line-height: 22px;
  }


  .helowe {
    font-size: 12px !important;
  }

  .GlobalDiv1 {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px !important;
  }

  .globalPara {
    font-size: 10px;
    line-height: 14px;
  }

  .CardInput::placeholder {
    font-size: 12px;
  }

  .custom-dropdown {
    font-size: 12px;
  }

  .break {
    display: none;
  }
}

@media (max-width:335px) {
  .skainetcontent2 {
    line-height: 14px;
  }

  .hover_list__item {
    line-height: 12px;
  }

  .div1leftButtons {
    width: 135px !important;
  }

  .hover_list__item__1 {
    font-size: 11px !important;
    line-height: 11.95px !important;
  }

}