.Content {
    /* max-width: 1176px; */
    width: 100%;
    padding: 40px 24px 40px 25px;
    background: #111214;
    min-height: 100vh;
}

.ContentLibraryTop {
    max-width: 1, 089px;
    flex-wrap: wrap;
    /* height: 200px; */
    gap: 22px;
    display: flex;
    flex-direction: row;
    justify-content: left;

}

.ContentLibraryTop_Div1 {
    width: 370px;
    box-shadow: 0px 0px 0px 4px transparent;
    border: 1px solid transparent;
    /* padding: 27px 35px 27px 36px; */
    padding: 15px 24px;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background: #24262B;
    cursor: pointer;
}

.DropDownmenuOption2 {
    margin: 0px !important;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 21.7px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
}



.ContentLibraryTop_Div1:hover {
    border: 1px solid #0F6FFF;
    box-shadow: 0px 0px 0px 4px #0F6FFF40;
    background: #393C43;
}


.ContentLibraryTop_Div2 {
    display: flex;
    flex-direction: row;
    gap: 14px;
}


.ContentLibraryTop_Div2_p1 {
    font-family: Montserrat-SemiBold;
    font-size: 16px;
    font-weight: 600;
    line-height: 24.8px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    margin: 0px !important;
}


.ContentLibraryTop_Div2_p2 {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 17.4px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #9EA0A5;
    margin: 0px !important;
}

.dropdown-toggle::after {

    display: none !important;
}

.DropDownmenuOptionDivvvvv2 {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 12px !important;
    border: 1px solid transparent !important;
    /* 
  width: Hug (182px)px;
  height: Hug (40px)px; */
    padding-bottom: 18px !important;
    border-radius: 8px !important;
}

.DropDownmenuOptionDivvvvv3 {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 12px !important;
    border: 1px solid transparent !important;
    /* 
  width: Hug (182px)px;
  height: Hug (40px)px; */

    border-radius: 8px !important;
}

.dropdownmain11 .dropdown-item:hover {
    background-color: transparent !important;
    /* Removes the hover background */
    color: transparent !important;
    outline: none !important;
    /* Removes focus outline if any */
}


@media (max-width:700px) {
.Content{
padding-left: 0px;
}}

@media (max-width:515px) {
    .ContentLibraryTop_Div1 {

        width: 100%;
    }

    .dropdownsetting {
        padding: 12px !important;
    }

    .DropDownmenuOptionDivvvvv2 {
        padding-bottom: 8px !important;

    }

    .Content {

        padding: 30px 0px 40px 0px;


    }

}




@media (max-width:400px) {

    .ContentLibraryTop_Div2_p1 {
        font-size: 14px;
    }

    .ContentLibraryTop {
        gap: 10px;
    }

    .ContentLibraryTop_Div1 {
        gap: 15px;
    }

    .ContentLibrary1_div1_p1,
    .ContentLibrary1_div1_p1span1,
    .ContentLibrary1_div1_p1span2,
    .ContentLibrary1_div1_p1span3 {
        font-size: 11px !important;
        line-height: 10px !important;
    }

}

@media (min-width: 600px) {
    .css-1k455el {
        min-height: 40px;
    }
}