.SignUpMainNext {
    width: 100%;
    background: #111214;
    display: flex;
    height: 100vh;
    overflow-y: hidden;
  }
  
  /* .SignUpImgNext {
    width: 50%;
  }
   */
  .SignUpMain11Next {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
  }
  
  .SignUpMain1Next {
    width: 420px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-width: none;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 5px;
    padding-left: 5px;
  }
  
  .SignUpMain1_1Next {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 65px;
    align-items: center;
  }
  
  .SignUpMain1_1ptagNext {
    font-family: Montserrat-Bold;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #ffffff;
    margin: 0px !important;
    white-space: nowrap;
  }
  
  .togglePasswordBtnNext {
    border: none;
    outline: none;
    background: none;
  }
  
  .SignUpMain1_2Next {
    display: flex;
    flex-direction: column;
    gap: 24px;
    /* margin-bottom: 60px; */
  }
  
  .SignUpMain1_2_firstNext {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .SignUpMain1_2_first_PNext {
    font-family: Montserrat-Bold;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.005em;
    text-align: left;
    color: #ffffff;
    margin: 0px !important;
  }
  
  .SignUpMain1_2_first_DivNext {
    width: 100%;
    height: 56px;
    padding: 16px;
    border-radius: 16px;
    background: #24262b;
    display: flex;
    gap: 8px;
    align-items: center;
  }
  
  .SignUpMain1_2_first_DivNext:focus-within {
    border: 1px solid #0f6fff;
    box-shadow: 0px 0px 0px 4px #164bf940;
    background: #393c43;
  }
  
  .SignUpMain1_2_first_Div_TNext {
    font-family: Montserrat-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.006em;
    color: #d7d8d9;
    margin: 0px !important;
    background: transparent;
    border: none;
    width: 100%;
  }
  
  .SignUpMain1_2_first_Div_TNext:focus {
    outline: none;
  }
  
  .SignUpMain1_2_first_Div_TNext::placeholder {
    font-family: Montserrat-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.006em;
    color: #d7d8d9;
    margin: 0px !important;
  }
  
  .SignUpMain1_2BtnNext {
    width: 100%;
    height: 56px;
    padding: 16px 28px 16px 28px;
    border-radius: 16px;
    background: #0f6fff;
    font-family: Montserrat-SemiBold;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    border: none;
    margin-top: 60px !important;
  }
  .Forgot-Password:hover{
    color: #FFFFFF;
  }
  
  .Forgot-Password{
    width: fit-content;
    cursor: pointer;
 font-family: Montserrat;
font-size: 14px;
font-weight: 500;
line-height: 17.07px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #9EA0A5;
margin: 8px 0px 0px 0px !important;

  }
  @media (max-width: 1000px) {
    .SignUpImgNext{
        display: none !important;
    }
    .SignUpMain11Next {
        width: 100% !important;
    
    }
    .SignUpMain1Next{
    width: 100% !important;
    padding: 0px 50px;
    }
  }
  @media (max-width: 600px) {
    .SignUpMain1Next{
        width: 100% !important;
        padding: 0px 25px;
        }
  }