.CourseOverview-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 24px 24px 24px 0px;
  background: #111214;

}

.Course-Overview-heading-part {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 24px;
  padding-left: 20px;
}

.Course-Overview-heading-part h2 {
  font-family: "Montserrat-SemiBold";
  margin: 0px !important;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
}

.Course-Overview-heading-part p {
  margin: 0px !important;
  font-family: "Montserrat-Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #9EA0A5;
}

.overview-video-and-course-content-main {
  display: flex;
  gap: 19px;
  align-items: flex-start;
}

.overview-video-and-course-content-main-left {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 65%;
}

.overview-video-and-course-content-main-left-bottom-overview {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

@media (max-width: 1000px) {
  .overview-video-and-course-content-main {
    display: flex;
    flex-direction: column;
  }

  .overview-video-and-course-content-main-left {
    width: 100%;
  }

  .overview-video-and-course-content-main-right {
    width: 100% !important;
  }

  .overview-video-and-course-content-main-left {
    padding: 24px 0px !important;
  }
}

@media (max-width: 500px) {
  .video-play-and-stop-the-btn--main----side-left {
    gap: 4px !important;
  }

  .video-play-and-stop-the-btn {
    gap: 4px !important;
  }
}

.overview-video-and-course-content-main-left-bottom-overview-first {
  display: flex;

  flex-direction: column;
}

.overview-video-and-course-content-main-left-bottom-overview-first h2 {
  font-family: "Montserrat-Bold";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.015em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
  margin: 0px;
  margin-bottom: 10px;
}

.overview-video-and-course-content-main-left-bottom-overview-first p {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #9EA0A5;
  overflow-wrap: break-word;
  margin: 0px;
}

.break-word-content {
  overflow-wrap: break-word;
  /* Ensures lines break properly */
  word-wrap: break-word;
  /* Compatibility with older browsers */
  white-space: normal;
  /* Ensures the text wraps to the next line */
}

.using-for-monst-family p strong {
  background: transparent !important;
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  text-align: left !important;
  text-underline-position: from-font !important;
  text-decoration-skip-ink: none !important;
  color: #9EA0A5 !important;
}

.using-for-monst-family p span {
  background: transparent !important;
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  text-align: left !important;
  text-underline-position: from-font !important;
  text-decoration-skip-ink: none !important;
  color: #9EA0A5 !important;
}

.using-for-monst-family ul li {
  background: transparent !important;
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  text-align: left !important;
  text-underline-position: from-font !important;
  text-decoration-skip-ink: none !important;
  color: #9EA0A5 !important;
}

.using-for-monst-family ul li span {
  background: transparent !important;
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  text-align: left !important;
  text-underline-position: from-font !important;
  text-decoration-skip-ink: none !important;
  color: #9EA0A5 !important;
}

.using-for-monst-family ul li strong {
  background: transparent !important;
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  text-align: left !important;
  text-underline-position: from-font !important;
  text-decoration-skip-ink: none !important;
  color: #9EA0A5 !important;
}

.using-for-monst-family ul li p {
  background: transparent !important;
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  text-align: left !important;
  text-underline-position: from-font !important;
  text-decoration-skip-ink: none !important;
  color: #9EA0A5 !important;
}

.using-for-monst-family ol li {
  background: transparent !important;
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  text-align: left !important;
  text-underline-position: from-font !important;
  text-decoration-skip-ink: none !important;
  color: #9EA0A5 !important;
}

.using-for-monst-family ol li span {
  background: transparent !important;
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  text-align: left !important;
  text-underline-position: from-font !important;
  text-decoration-skip-ink: none !important;
  color: #9EA0A5 !important;
}

.using-for-monst-family ol li strong {
  background: transparent !important;
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  text-align: left !important;
  text-underline-position: from-font !important;
  text-decoration-skip-ink: none !important;
  color: #9EA0A5 !important;
}

.using-for-monst-family ol li p {
  background: transparent !important;
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  text-align: left !important;
  text-underline-position: from-font !important;
  text-decoration-skip-ink: none !important;
  color: #9EA0A5 !important;
}



.Instagram-Mastery {
  font-family: "Montserrat-SemiBold";
  font-size: 26px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0px;
  color: #FFFFFF;
}

.span-of-listing {
  font-family: "Montserrat-Bold";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.015em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
  margin: 0px;
}

.course-content {
  color: #ffffff;
  padding: 20px;
  border-radius: 8px;

}

.overview-video-and-course-content-main-right {
  width: 40%;
}

.header {
  text-align: left;
  margin-bottom: 15px;
}

.header h3 {
  font-size: 18px;
  margin: 0;
}

.header p {
  font-size: 14px;
  color: #bbb;
}

.accordion {
  border-top: 1px solid #444;
  margin-top: 10px;
}

.overview-video-and-course-content-main-right {
  display: flex;
  flex-direction: column;
}

.Course-Content-and-lecture {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.Course-Content-and-lecture h2 {
  font-family: "Montserrat-Medium";
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
  margin: 0px;
}

.Course-Content-and-lecture p {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0px;
  color: #9EA0A5;
}

.accordion-section {
  border-bottom: 1px solid #0F6FFF80
}

.accordion-title {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  cursor: pointer;
}

.accordion-title span {
  font-size: 16px;
}

.accordion-content {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.content-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.content-item input[type="radio"] {
  accent-color: #007bff;
}

.content-item label {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.content-item .time {
  color: #aaa;
}

.load-more {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  text-align: center;
}

.load-more:hover {
  background-color: #0056b3;
}





.accordion-content {

  display: flex;
  position: relative;
}

.vertical-line {
  position: absolute;
  width: 4px;
  background: #393c43;
  left: 7px;
  /* Positions the line to align with the checkboxes */
  top: 0;
  bottom: 0;
  z-index: 0;
}

.lesson-item {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  z-index: 1;
  /* Ensures checkboxes appear above the vertical line */
  margin-bottom: 20px;
  /* Space between lessons */
}

.checkbox {
  width: 16px;
  height: 16px;
}

.lesson-label {
  font-size: 14px;
  flex: 1;
  /* Ensures text aligns neatly in one row */
}

.lesson-duration {
  font-size: 12px;
  color: #aaaaaa;
  /* Light gray text */
}

.accordion-section {
  color: #ffffff;
  /* White text */
  margin-top: 21px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  /* Pointer cursor for header click */
  padding-bottom: 10px;
}

.lesson-label-Overview-about {
  display: flex !important;
  flex-direction: column !important;
  gap: 6px;
}

.header-left {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.section-title {
  font-family: "Montserrat-Medium";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
  margin: 0px;
}

.one-video-t-mint {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0px;
  color: #9EA0A5;
  white-space: nowrap;
}

.section-duration {
  display: flex;
  align-items: center;
  gap: 5px;
}

.accordion-arrow {
  font-size: 16px;
  transform: rotate(0deg);
  /* Default closed arrow (points down) */
  transition: transform 0.3s ease;
  /* Smooth rotation */
}

.accordion-arrow.open {
  transform: rotate(180deg);
  /* Rotated open arrow (points up) */
}


.accordion-content {

  display: flex;
  padding-left: 24px;
}

.vertical-line {
  position: relative;
  bottom: 2px;
  width: 4px;
  background: #393c43;
  height: 100%;
}

.vertical-line:before {
  content: "";
  display: block;
  position: absolute;
  top: -4px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #393c43;
}

.lesson-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkbox {
  width: 16px;
  height: 16px;
  position: relative;
  right: 6px;
  bottom: 11px;
  background: #0F6FFF;
  border: 1px solid #0F6FFF
}

.lesson-label {
  font-family: "Montserrat";
  color: #FFFFFF;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0px;

}

.empty2ndtext {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.015em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #9EA0A5;
  margin: 0px !important;

}

.innerrrrrrrrrbottom {
  font-family: Montserrat-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.015em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0F6FFF;
  margin: 0px !important;
}
.emptystatebutton{
  width: 221px;
  height: 56px;
  padding: 17px;
  border-radius: 16px;
  background: #0F6FFF;
  font-family: Montserrat;
font-size: 16px;
font-weight: 600;
line-height: 22px;
letter-spacing: -0.006em;
text-align: center;
border: none;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: white;
margin: 0px !important;
}
.Empty-State {
  padding: 80px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 26px;
  width: 100%;
  background-color: #24262B;
  border-radius: 16px;

}

.lesson-duration {
  font-size: 12px;
  color: #aaaaaa;
  /* Light gray text */
}


.checkbox:checked {
  background: #0F6FFF;
  border: 1px solid #0F6FFF;
}



.video-player-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  /* Set to full width */
  height: 360px;
  /* Fixed height */
  margin: auto;
  position: relative;
}

.video-element2 {
  width: 100% !important;
  /* height: 100% !important; */
  /* Full width */
  height: calc(100% - 50px);
  /* Adjust to leave space for controls */
  background: #000;
  object-fit: contain;
  /* Ensures video scales correctly */
}






.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* Full width */
  padding: 10px 10px 10px 0px;
  /* Adjusted padding */
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 0 0 8px 8px;
  height: 50px;
  /* Ensure controls height fits */
  box-sizing: border-box;
}




.progress-bar-container {
  width: 100%;
  /* Full width */
  height: 3px;
  background-color: #ccc;
  position: relative;
  left: 0;
  /* Adjust to align properly */
}

.progress-bar {
  width: 0;
  /* Will dynamically update */
  height: 100%;
  background-color: #EF4444;
  border-radius: 4px;
}

.progres-again-try {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.video-play-and-stop-the-btn--main----side {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* Full width for buttons */
}

.video-play-and-stop-the-btn--main----side-left {
  display: flex;
  gap: 8px;
}

.mini-pause-use-in-med {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.time-display {
  font-size: 14px;
  white-space: nowrap;
}



.Load-More-Courses {
  width: 319px;
  height: 56px;
  border-radius: 16px;
  background: #0F6FFF;

}

.video-play-and-stop-the-btn {
  display: flex;
  align-items: center;
  gap: 10px;
}

.video-play-and-stop-the-btn p {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
  margin: 0px;
}

.overview-video-and-course-content-main-right {
  display: flex;
  flex-direction: column;
  gap: 21px;
  /* Space between items */
}

.bottom-button {
  width: 100%;
  background: #0F6FFF;
  border-radius: 16px;
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat-SemiBold";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.006em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
  border: none;
}

.bottom-button:hover {
  opacity: 0.7;
}

.using-for-monst-family {
  font-family: "Montserrat-Medium";
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
}

@media (max-width: 1000px) {}

@media (max-width: 600px) {
  .DrawerImages-profil {
    width: 24px;
  }

  .app-title {
    font-size: 17px !important;
  }

  .mini-pause-use-in-med {
    width: 20px;
  }

  .video-play-and-stop-the-btn {
    display: flex;
    gap: 1px !important;
  }
}

.video-play-and-stop-the-btn--main----side {
  display: flex;

  justify-content: space-between !important;
}

.video-play-and-stop-the-btn--main----side-left {
  display: flex;
  gap: 10px;
}

.lesson-0-0 {
  width: 16px;
  height: 16px;
  position: relative;
  right: 4px;
  bottom: 11px;
  padding: 1.33px;
  background: #0F6FFF !important;
  border: 1px solid #0F6FFF;
}

/* Style for unchecked state */
.checkbox {
  background: #111214 !important;
  border: 1px solid #0F6FFF !important;
  width: 15px;
  /* Optional: adjust size */
  height: 15px;
  /* Optional: adjust size */
  cursor: pointer !important;
}

/* Style for checked state */
.checkbox:checked {
  background: #0F6FFF !important;
  border: 1px solid #0F6FFF !important;
}

/* Default state when checkbox is not selected */
.checkbox {
  background: #111214;
  border: 1px solid #0F6FFF;
  width: 15px;
  height: 15px;
  transition: background 0.3s ease, border 0.3s ease;
}

/* Selected state */
.checkbox:checked {
  background: #0F6FFF;
  border: 1px solid #0F6FFF;
}


@media (max-width: 600px) {

  .video-play-and-stop-the-btn {
    display: flex;
    gap: 1px !important;
  }
}

.video-play-and-stop-the-btn2 {
  padding-left: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
}

.new------------again-stye {
  margin: 0px;
}

.checkbox {
  width: 16px;
  height: 16px;
  background: #111214;
  border: 1px solid #0F6FFF;
  appearance: none;
  /* Removes the default checkbox styling */
  cursor: pointer;
  padding: 5px 7px;
  border-radius: 3px;
}

.sectionnn {
  margin: 0px !important;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
}

.section-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  align-items: flex-start;
}

.play-div {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  margin-top: 7px !important;
}

.subsection---2 {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 26px !important;
  margin-left: 8px !important;
}

.subsection--- {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 8px !important;
  margin-left: 8px !important;
}

.line-grey {
  width: 4px;
  background-color: #393C43;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}

.subsection-divv {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 24px;
}


.hrblueline {
  margin: 0px !important;
  border: 1px solid #0F6FFF;
  margin-top: 12px !important;
}

.hrblueline2 {
  margin: 0px !important;
  border: 1px solid #0F6FFF;
  margin-top: 16.26px !important;
}

.submaindiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

video::-webkit-media-controls {
  display: none !important;
}

video::-webkit-media-controls-start-playback-button {
  display: none !important;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: black;
  /* Optional, to mimic fullscreen effect */
  z-index: 9999;
  /* Ensure it's on top */
}







.getting {

  margin: 0px !important;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
}

.video--text {
  margin: 0px !important;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #9EA0A5;
}

.minvid {
  margin: 0px !important;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #9EA0A5;
}

.checkbox:checked {
  width: 15.33px;
  /* Adjust size when checked */
  height: 15.33px;
  background: #0F6FFF;
  border: 1px solid #0F6FFF;
  /* Retain border if necessary */
}

.checkbox:checked::after {
  content: "";
  /* Adds content for the tick */
  position: absolute;
  top: 3px;
  /* Adjust as necessary for centering */
  left: 5px;
  /* Adjust as necessary for centering */
  width: 1px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(40deg);
}



@media (max-width:750px) {
  .Empty-State {
    padding: 40px 10px;

    gap: 14px;

  }

  .Instagram-Mastery {
    font-size: 21px;
  }

  .overview-video-and-course-content-main-left-bottom-overview-first h2 {
    font-size: 16px;
  }

  .NoCourse {
    font-size: 20px !important;
  }

  .content-Image {
    height: 80px;
    width: 80px;
  }
}


@media (max-width:450px) {
  .emptystatebutton {
    width: 100%;
    height: 45px;
    padding: 10px;
  }
  .empty2ndtext {
    font-size: 14px;
    line-height: 17px;
  }
  .video-player-container {
    height: 230px;
  }

  .CourseOverview-main-container {
    padding: 0px;
  }

  .Empty-State {
    padding: 20px 8px;

    gap: 10px;

  }


  .NoCourse {
    font-size: 16px !important;
  }

  .content-Image {
    height: 50px;
    width: 50px;
  }

  .using-for-monst-family p strong {

    font-size: 13px !important;

  }

  .using-for-monst-family p span {
    font-size: 13px !important;
  }

  .using-for-monst-family ul li {
    font-size: 13px !important;
  }

  .using-for-monst-family ul li span {
    font-size: 13px !important;
  }

  .using-for-monst-family ul li strong {
    font-size: 13px !important;
  }

  .using-for-monst-family ul li p {
    font-size: 13px !important;
  }

  .using-for-monst-family ol li {
    font-size: 13px !important;
  }

  .using-for-monst-family ol li span {
    font-size: 13px !important;
  }

  .using-for-monst-family ol li strong {
    font-size: 13px !important;
  }

  .using-for-monst-family ol li p {
    font-size: 13px !important;
  }

  .using-for-monst-family {
    font-size: 13px !important;
  }



}

@media (max-width:450px) {
  .video-play-and-stop-the-btn2 {
    padding-left: 5px;
    gap: 5px;
  }

  .video-element2 {}


}

@media (max-width:350px) {
  .NoCourse {
    font-size: 14px !important;
  }
}