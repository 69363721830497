.FunnelSecond-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: url("/public/Images/FunnelSecond/hire\ skainet.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  padding: 176px 0px 0px 0px !important;
}
.FunnelSecond-main-button-text-why {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.tryy-funnel-heading {
  font-family: Exo-Extra-Bold;
  font-size: 60px;
  font-weight: 800;
  line-height: 64px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0px !important;
  text-transform: uppercase;
  color: #ffffff; /* Base color of the text is white */
  text-shadow: 3px 3px 2.5px #0033cc,
    /* Blue shadow for the bottom */ 4px 4px 0px #000033; /* Dark blue shadow for deeper shading */
}
.dificult-span {
  color: #0f6fff; /* Bright blue text color */
  text-shadow: 3px 3px 0px #032ca8,
    /* White shadow for the top */ 4px 4px 0px #03032e; /* Darker shadow for depth */
}
.Dedicated-Marketing-Team {
  width: 269px;
  padding: 15px 0px !important;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #0f6fff33;
  border: 1px solid #0f6fff99;
  font-family: Exo;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}
.tryy-funnel-heading-main {
  display: flex;
  flex-direction: column;
  margin-top: 22.75px;
}

.lorem-text-paragraph {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  margin: 20px 0px 0px 0px;
}

.completed-projects-main-part {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.completed-projects-main-part h2 {
  font-family: Exo-Bold;
  font-size: 70px;
  font-weight: 700;
  line-height: 84px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background: linear-gradient(169.97deg, #ffffff 8.65%, #cdcdcd 93.64%);
  -webkit-background-clip: text; /* Clip gradient to text (for WebKit browsers) */
  -webkit-text-fill-color: transparent; /* Makes text transparent to reveal gradient */
  background-clip: text; /* Standard property for modern browsers */
  color: transparent; /* Fallback in case gradient is unsupported */
  margin: 0px !important;
  text-align: center;
}

.completed-projects-main-part p {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  margin: 0px !important;
}
.completed-projects-main-part-all-main {
  display: flex;
  align-items: center;
  gap: 100px;
  margin-top: 48px;
}
.book-a-call-button {
  padding: 25px 30px 25px 30px;
  border-radius: 16px;
  background: #0f6fff;
  font-family: Exo;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  margin: 53px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
}

.funnel-border-image-main {
  display: flex;
  justify-content: center;
  padding-top: 110px;
}

.hiring-team-skainet-main{
    display: flex;
    width: 100%;
    padding: 125px 100px;
    justify-content: space-between;
  
}
.hiring-team-skainet-main-heading-left{
    display: flex;
    flex-direction: column;
}
.hiring-team-skainet-main-heading-left h2{
    font-family: Exo-Extra-Bold;
    font-size: 60px;
    font-weight: 800;
    line-height: 64px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0px !important;
    text-transform: uppercase;
    color: #ffffff; /* Base color of the text is white */
    text-shadow: 2.5px 2.5px 2.5px #0033cc,
      /* Blue shadow for the bottom */ 4px 4px 0px #000033; /* Dark blue shadow for deeper shading */
}

.hiring-skainet-team-para{
    display: flex;
    flex-direction: column;
}
.hiring-skainet-team-para p{
    font-family: Montserrat;
font-size: 20px;
font-weight: 500;
line-height: 28px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FFFFFF;
margin: 0px !important;
}

.all-team-and-manager-main{
display: flex;
flex-direction: column;
gap: 20px;
margin-top: 50px;
}

.all-team-and-manager-main-heading-and-image{
display: flex;
align-items: center;
gap: 12px;
}
.all-team-and-manager-main-heading-and-image h3{
    font-family: Montserrat;
font-size: 22px;
font-weight: 500;
line-height: 32px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FFFFFF;
margin: 0px !important;
} 

@media (max-width:1330px) {
  .hiring-team-skainet-main{
    flex-direction: column;
    gap: 40px;
  }
  
}

@media (max-width:1330px){
  .FunnelSecond-main-button-text-why{
    padding: 0px 30px;
  }
  
}
@media (max-width:768px){
  .tryy-funnel-heading{
    font-size: 54px;
    

  }
  .FunnelSecond-main{
   padding-top: 40px !important;
  }
  .lorem-text-paragraph{
    font-size: 20px;
  }
  .completed-projects-main-part h2{
    font-size: 44px;
  }
  .completed-projects-main-part p {
    font-size: 20px;
  }
  .hiring-team-skainet-main-heading-left h2{
    font-size: 44px;
  }
  .all-team-and-manager-main-heading-and-image h3{
    font-size: 20px;
  }
  .hiring-team-skainet-main{
    padding: 60px 30px;
  }
  .hiring-team-skainet-main-right{
    width: 100%;
  }
  .lorem-text-paragraph{
    text-align: left;
  }
  .divider-align{
    width: 100%;
    padding: 30px;

  }
 
  .completed-projects-main-part-all-main{
    gap: unset;
  }
  .FunnelSecond-main{
    padding-top: 65px;
  }
}
@media (max-width:500px) {
  .tryy-funnel-heading{
    font-size: 21px;
    font-weight: 600;
    line-height: 34px;
  }
  .lorem-text-paragraph{
    font-size: 15px;
    line-height: 20px;
  }
  .funnel-border-image-main{
    padding-top: 10px;
  }
  .hiring-team-skainet-main{
    padding: 20px 30px;
  }
  .completed-projects-main-part p{
    font-size: 15px;
    line-height: 20px;
  }
  .hiring-team-skainet-main-heading-left h2{
    font-size: 21px;
    line-height: 34px;
  }
  .hiring-skainet-team-para p{
    font-size: 15px;
    line-height: 20px;
  }
  .all-team-and-manager-main {
    margin-top: 25px;
  }
  .all-team-and-manager-main-heading-and-image h3{
    font-size: 11px;
    line-height: 19px;
  }
  .all-team-and-manager-main-heading-and-image{
    gap: 5px;
  }
  .book-a-call-button{
    margin-top: 30px;
    padding: 15px 20px;
  }
  .dificult-span {
    color: #0f6fff;
    text-shadow: 2px 2px 0px #032ca8, 4px 4px 0px #03032e;
}
  }

  .book-a-call-button:hover{
    background: #2253e7;
  }
  .trusted-btn:hover{
    background: #2253e7;
  }